Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

// Customizable Area Start
exports.errorEmailNotValid = "Email not valid.";
exports.newLoginAPiEndPoint = "bx_block_login/login";
exports.newCaregiverLoginAPiEndPoint = "bx_block_login/login/caregiver";
exports.checkSubscriptionAPiEndPoint = "bx_block_stripe_integration/subscriptions/has_active_subscription";
exports.checkCaregiverSubscriptionAPiEndPoint = "bx_block_cfpingenerator/caregivers/hospice_subscription_status";
exports.errorPasswordNotValid = "Password not valid.";
exports.placeHolderEmail = "Your email";
exports.titleEmail = "Email";
exports.titlePhone = "Phone number";
exports.titlePassword = "Password";
exports.placeHolderPassword = "Your password";
exports.placeHolderPhone = "Your contact number";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "Log in";
exports.labelRememberMe = "Remember me";
exports.btnTxtRegister = "Register";
exports.labelOr = "Don’t have an account?";
exports.labelTitle = "Log in to Partner For Care";
exports.firstInputKeyboardStyle = "email-address";
exports.clickInfo = "Click here for info"
exports.informationDes = "If you are a hospice, kindly click on 'Hospice' and proceed with registration. If you are a caregiver, we encourage you to reach out to your hospice and request to be added as a caregiver."
exports.closeButton = "Close"
exports.modalInformationTitle = "Information"
exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";
// Customizable Area End