import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  categoriesArray: any;
  category: string;
  subCategory: string;
  isVisible: boolean;
  dropdownCategoryStatus: boolean;
  activeModalType: string;
  selectedCategoryID: any;
  loading: boolean
  fullResponseData: string
  isShowNurseContact: boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CategoriessubcategoriesController extends BlockComponent<
  Props,
  S,
  SS
> {
  getCategoriesApiCallId: any;
  deleteCategoriesApiCallId: any;
  deleteSubCategoriesApiCallId: any;
  addCategoryApiCallId: any;
  addSubCategoryApiCallId: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      token: "",
      categoriesArray: [],
      category: "",
      subCategory: "",
      isVisible: false,
      dropdownCategoryStatus: false,
      activeModalType: "",
      selectedCategoryID: [],
      loading: true,
      fullResponseData: "",
      isShowNurseContact: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
  }
  
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token =  localStorage.getItem("token") ?? ""
      this.setState({ token: token }, () => {
        this.getCategories();
      });

    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.handleResponseAPI(message)
  }
    // Customizable Area End
  }

  // Customizable Area Start
  handleResponseAPI = (message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    var responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(responseJson.errors){
      this.setState({loading: false})
      this.parseApiCatchErrorResponse(responseJson.errors);
      return
    }
      if (apiRequestCallId === this.getCategoriesApiCallId) {
        let listData = []
        const categoryId = this.props.navigation.getParam('id')
        const subCategoriesId = this.props.navigation.getParam('subId')
        const subSubCategoriesId = this.props.navigation.getParam('subsubId')
        let isShow = false
        if(categoryId){
          listData = responseJson.Categories.data
          isShow = responseJson.Catalogue.name.includes("Nurse")
        }
        if(subCategoriesId){
          listData = responseJson.sub_categories.data
        }
        if(subSubCategoriesId){
          listData = responseJson.sub_subcategories.data
        }
        this.setState({ categoriesArray:listData, loading: false, fullResponseData: JSON.stringify(responseJson), isShowNurseContact: isShow});
  }
  }
  setCategoryTxt = (text: string) => {
    this.setState({ category: text });
  };
  setSubCategoryTxt = (text: string) => {
    this.setState({ subCategory: text });
  };
  clickCategory = (item: any, Index: number) => {
    let array = this.state.categoriesArray;
    let idarray = this.state.selectedCategoryID;
    let index = idarray.indexOf(item.attributes.id);

    if (index > -1) {
      idarray.splice(index, 1);
      array[Index].Check = false;
      this.setState({ categoriesArray: array });
    } else {
      idarray.push(item.attributes.id);
      array[Index].Check = true;
      this.setState({ categoriesArray: array });
      this.setState({ selectedCategoryID: idarray });
    }
  };

  toggleModal = (type: string) => {
    this.setState({ activeModalType: type, isVisible: !this.state.isVisible });
  };

  expandCategoryView = () => {
    this.setState({
      dropdownCategoryStatus: !this.state.dropdownCategoryStatus
    });
  };
  expand = (id: string) => {
    let array = this.state.categoriesArray;
    for (let i = 0; i < array.length; i++) {
      if (array[i].id === id) {
        array[i].expand = !array[i].expand;
      }
    }
    this.setState({ categoriesArray: array });
  };

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  addCategory = () => {
    return true
  };
  addSubCategory = () => {
    return true
  };

  deleteCategories = (id: number) => {
    return true
  };

  deleteSubCategories = (id: number) => {
    return true
  };
  
  handleClickCategory = (item: {
    id: string
    type: string
    attributes: {
      id: number
      name: string
      has_sub_categories: boolean
      has_content: boolean
      content_id: string
      image: {
        id: number
        url: string
      }
    }
  }) => {
    const categoryId = this.props.navigation.getParam('id')
    const subCategoriesId = this.props.navigation.getParam('subId')
    if (item.attributes.has_sub_categories) {
      if (categoryId) {
        this.props.navigation.navigate("Subcategories", { subId: item.id })
        return
      }
      if (subCategoriesId) {
        this.props.navigation.navigate("SubSubcategories", { subsubId: item.id })
        return
      }
    }
    if(item.attributes.has_content){
      this.props.navigation.navigate("ContentManagement",  {contentId: item.attributes.content_id })
    } 
  }
  getCategories = () => {  
    this.setState({loading: true})
    const categoriesId = this.props.navigation.getParam('id')
    const subCategoriesId = this.props.navigation.getParam('subId')
    const subSubCategoriesId = this.props.navigation.getParam('subsubId')
    let id = categoriesId
    let path = configJSON.categoryAPIEndPoint
    if(subCategoriesId){
      path = configJSON.subCategoryAPIEndPoint
      id = subCategoriesId
    }
    if(subSubCategoriesId){
      path = configJSON.subsubCategoryAPIEndPoint
      id = subSubCategoriesId
    }
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCategoriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      path + id
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getPadding = () => {
    const FULL_WIDTH = window.innerWidth;
    const IS_MOBILE_MODE = FULL_WIDTH <= 600
    const ADDITIONAL_SPACE = IS_MOBILE_MODE ? 0 : 40
    const ITEM_SIZE = IS_MOBILE_MODE ? 114 : 230
    const GAP = IS_MOBILE_MODE ? 0 : 26
    const WORKING_WIDTH = FULL_WIDTH - ADDITIONAL_SPACE
    let numberItem = Math.floor(WORKING_WIDTH / ITEM_SIZE)
    if((numberItem * ITEM_SIZE + numberItem * GAP) > WORKING_WIDTH){
      numberItem = numberItem -1
    }
    const lastSpace = WORKING_WIDTH - (numberItem * ITEM_SIZE) 
    const padding = lastSpace - ((numberItem - 1) * GAP)
    return padding / 2
  }
  // Customizable Area End
}
