// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export interface IBillingHistory {
  date: string,
  transaction: string,
  price: number,
  type: number,
  payment_type: string,
  status: boolean,
}


export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  token: string
  fetching: boolean
  listBillingHistory: IBillingHistory[]
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ViewBillingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  
  apiMyBillingHistoryCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      token:"",
      fetching: false,
      listBillingHistory: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token =  localStorage.getItem("token") ?? ""
      this.setState({ token: token}, () => {
        this.handleGetListBillingHistory()
      });
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.handleResponseMessage(message)
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
  }
  getToken=()=>{
    const message: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(message);
  }
  handleResponseMessage = (message: Message) => {
    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if(errorReponse){
      this.setState({ fetching:false,})
      this.parseApiCatchErrorResponse(errorReponse);
      return
    }

    this.handleSubscriptionApiMessage(message)
  }
  
  handleSubscriptionApiMessage = (message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.apiMyBillingHistoryCallId) {
        this.setState({
          fetching: false,
          listBillingHistory: responseJson.data
        })
    }
    
  }
  
 handleGetListBillingHistory = () => {
  const header = {
   "Content-Type": configJSON.validationApiContentType,
   "token": this.state.token
 };
 const billingHistoryMessage = new Message(
   getName(MessageEnum.RestAPIRequestMessage)
 );
 this.apiMyBillingHistoryCallId = billingHistoryMessage.messageId;
 billingHistoryMessage.addData(
   getName(MessageEnum.RestAPIResponceEndPointMessage),
   configJSON.billingHistoryEndpoint
 );

 billingHistoryMessage.addData(
   getName(MessageEnum.RestAPIRequestHeaderMessage),
   JSON.stringify(header)
 );

 billingHistoryMessage.addData(
   getName(MessageEnum.RestAPIRequestMethodMessage),
   configJSON.validationApiMethodType
 );
 runEngine.sendMessage(billingHistoryMessage.id, billingHistoryMessage);
 return true;
}
goBackSetting = () => {
  this.props.navigation.navigate("Settings2")
}
goBackDashboard = () => {
  this.props.navigation.navigate("Dashboard")
}
  // Customizable Area End
}
