import React, { useEffect, useState } from "react";
import { Box, Grid, Divider } from "@mui/material";
import { styled, SxProps } from '@mui/system';

import ContentBreadcrumbs from "./ContentBreadcrumb.web";
import OverviewSection from "./OverviewSection.web";
import PlainTextItem from "./PlanTextItem.web";
import ItemWithImage from "./ItemWithImage.web";
import { OverviewAnnotation } from "./MethodOverviewSection.web";
import { CustomSectionContainer, SectionListLayout, SubcatContainer } from "./CustomContainer.web";
import { ISectionData } from "./SymptomARD.web";
import ReactHtmlParser from "./ReactHtmlParser";

import { IContentData, ITermDetail } from "../../blocks/contentmanagement/src/ContentManagementController";

const SectionTitleContainer = styled(Box)({
  fontWeight: '700',
  fontFamily: 'Lato',
  color: '#343C32',
  '& p': {
    margin: 0
  }
});

const LightSectionTitle = styled(SectionTitleContainer)({
  color: '#586554',
});

const BaseTextContainer = styled(SectionTitleContainer)({
  fontSize: '22px', 
  fontWeight: '400',
});

const BenefitCard = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '20px',
  backgroundColor: '#FFF',
  padding: '20px 12.5px',
  border: "1px solid #D6DCD5", 
  borderRadius: 8,
});

const DescImg = styled('img')({
  width: '120px',
  height: '120px',
  objectFit: 'contain'
});

const UnderstandingOpioid = ({ data, onNavigate }: { data: IContentData, onNavigate: (to: string) => void }) => {
  const { attributes } = data;
  const { title, terms } = attributes;

  const [sectionList, setSectionList] = useState<ISectionData[]>([]);

  useEffect(() => {
    if (terms) {
      terms.sort((a, b) => a.id - b.id); 
      const groupedData: { [key: string]: ITermDetail[] } = {};
      terms.forEach(term => {
        const key = term.term;
        if (!groupedData[key]) {
          groupedData[key] = [];
        }
        groupedData[key].push(term);
      });
      const sections = Object.entries(groupedData).map(group => {
        if (group[1].length > 1) {
          return {
            name: group[0],
            definition: '',
            images: [],
            children: group[1].map(each => {
              return { definition: each.definition, images: each.images }
            })
          }
        }
        return {
          id: group[1][0].id,
          name: group[0],
          definition: group[1][0].definition,
          images: group[1][0].images,
        }
      })
      setSectionList(sections)
    }
  }, [terms]);

  const renderSectionItem = (htmlString: string, withStyle?: SxProps, withTextStyle?: SxProps) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const listItems = Array.from(doc.querySelectorAll('li')).map(li => li.innerHTML);
    return listItems.map((item, index) => (<PlainTextItem key={index} style={{ ...withStyle }} textStyle={{ ...withTextStyle }}>{ReactHtmlParser(item)}</PlainTextItem>))
  }

  return (
    <>
      <ContentBreadcrumbs stacks='Dashboard/Medications/Pain/Opioid Pain Meds/Understanding Opioid Pain Meds' onNavigate={onNavigate} />
      <SectionListLayout>
        <OverviewSection 
          images={title ? title?.images.map(image => image.url) : []}
          name={title && ReactHtmlParser(title?.title)} 
          shortDesc={title && title?.desc && title?.desc !== '' && ReactHtmlParser(title?.desc)}
          annotation={sectionList.length > 0 &&
            <Box sx={{ padding: { xs: "0 15px", lg:'0 130px' } }}>
              <LightSectionTitle mb={2.5} sx={{ fontSize: { xs: 20, lg: 26 } }}>{ReactHtmlParser(sectionList[0]?.name)}</LightSectionTitle>
              <Grid container spacing={2.5} px={{ xs: 2, lg: 0 }}>
                {sectionList[0]?.children && sectionList[0]?.children.map((child, index) => (
                  <Grid item xs={12} lg={4}>
                    <BenefitCard key={index}>
                      {child.images.length > 0 && <DescImg src={child.images[0].url} alt={child.images[0].filename} />}
                      <BaseTextContainer textAlign="center" sx={{ fontSize: { xs: '20px', lg: '22px' } }}>{ReactHtmlParser(child.definition)}</BaseTextContainer>
                    </BenefitCard>
                  </Grid>
                ))}
              </Grid>
            </Box>
          }
          annotationStyle={{ marginTop: '20px' }}
        />
        <CustomSectionContainer variant="dark">
          {sectionList
            .filter(section => section.id && section.images.length > 0)
            .map((detail, index) => (
            <Box key={index}>
              <OverviewAnnotation 
                title={ReactHtmlParser(detail.name)}
                image={detail.images.length > 0 ? detail.images[0]?.url : ''}
                content={renderSectionItem(detail.definition)}
                layoutStyle={{ flexWrap: { xs: 'wrap', lg: 'nowrap' } }}
              />
              {index === 0 && <Divider sx={{ marginTop: 5, backgroundColor: '#586554' }} />}
            </Box>
          ))}
        </CustomSectionContainer>
        <CustomSectionContainer containerStyle={{ paddingTop: 0, paddingBottom: 0 }} contentStyle={{ flexDirection: 'row', flexWrap: 'wrap' }} variant="light">
          {sectionList
            .slice(1)
            .filter(section => section?.children && section?.children.length > 0)
            .map((section, index) => 
              <SubcatContainer
                key={index}
                containerStyle={{
                  marginTop: 0,
                  marginBottom: 0,
                  borderRadius: '8px', padding: '40px 20px', 
                  backgroundColor: index === 0 ? '#F2F4F1' : '#FEF2F2',
                  border: index !== 0 ? '2px dashed #F87171' : ''
                }}
                withHeading
                heading={ReactHtmlParser(section.name)}
                headingStyle={{ fontSize: { xs: 20, lg: 26 } }}
              >
                 <Grid container spacing={2.5}>
                  {section?.children && section?.children.map((child, index) => (
                    <>
                      <Grid key={`lg-${index}`} item xs={12} sx={{ display: { xs: 'none', lg: 'block'}  }}>
                        <ItemWithImage image={child?.images[0]?.url || ''} text={ReactHtmlParser(child?.definition)} imageSize={120} />
                      </Grid>
                      <Grid key={`xs-${index}`} item xs={12} sx={{ display: { xs: 'block', lg: 'none'}  }}>
                        <ItemWithImage image={child?.images[0]?.url || ''} text={ReactHtmlParser(child?.definition)} imageSize={60} />
                      </Grid>
                    </>
                  ))}
                </Grid>
              </SubcatContainer>)}
        </CustomSectionContainer>
        <CustomSectionContainer variant="light" containerStyle={{ paddingTop: 0, paddingBottom: { xs: '20px', lg: 0 } }}>
          <SubcatContainer
            containerStyle={{ 
              marginTop: { xs: 0, lg: '20px' },
              marginBottom: 0, 
              width: 'auto', 
              backgroundColor: '#F0E5FF', 
              border: '2px dashed #6200EA', 
              borderRadius: '8px',
              paddingX: { xs: '16px', lg: '130px'}, 
              paddingY: '20px' 
            }}
          >
            {renderSectionItem(sectionList[sectionList.length - 1]?.definition, { gap: { xs: '12px', lg: 0 } }, { fontWeight: '700', fontSize: { xs: 16, lg: 22 } })}
          </SubcatContainer>
        </CustomSectionContainer>
      </SectionListLayout>
    </>
  );
}

export default UnderstandingOpioid;
