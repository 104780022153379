import React, { useEffect, useState } from "react";
import { Box, Grid, Link } from "@mui/material";
import { styled } from "@mui/system";
import parse, { domToReact, Element, HTMLReactParserOptions } from "html-react-parser";

import ContentBreadcrumbs from "./ContentBreadcrumb.web";
import OverviewSection from "./OverviewSection.web";
import { SectionListLayout, CustomSectionContainer, SubcatContainer } from "./CustomContainer.web";
import { OverviewAnnotation } from "./MethodOverviewSection.web";
import PlainTextItem from "./PlanTextItem.web";
import ItemWithImage from "./ItemWithImage.web";
import { ISectionData } from "./SymptomARD.web";

import { IContentData, ITermDetail } from "../../blocks/contentmanagement/src/ContentManagementController";

const DivContainer = styled(Box)({
  borderRadius: "8px",
  border: "1px solid #D6DCD5", 
  background: "#FFF", 
  padding: "20px",
})

const CustomLink = styled(Link)({
  color: '#343C32',
  textDecorationColor: '#343C32'
})


function ShortnessOfBreath({ data, onNavigate }: { data: IContentData, onNavigate: (to: string) => void }) {
  const { attributes } = data;
  const { title, terms } = attributes;

  const parsingOptions: HTMLReactParserOptions = {
    replace(domNode) {
      if (domNode instanceof Element && domNode.name === 'li') {
        return (
          <PlainTextItem>{domToReact(domNode.children, parsingOptions)}</PlainTextItem>
        )
      }
      if (domNode instanceof Element && domNode.name === 'ul') {
        return (
          <>{domToReact(domNode.children, parsingOptions)}</>
        )
      }
      if (domNode instanceof Element && domNode.name === 'a') {
        return (
          <CustomLink href={domNode.attribs['href']} target={domNode.attribs['target']}>
            {domToReact(domNode.children, parsingOptions)}
          </CustomLink>
        )
      }
    },
  }

  const [sectionList, setSectionList] = useState<ISectionData[]>([]);

  useEffect(() => {
    if (terms) {
      terms.sort((a, b) => a.id - b.id); 
      const groupedData: { [key: string]: ITermDetail[] } = {};
      terms.forEach(term => {
        const key = term.term;
        if (!groupedData[key]) {
          groupedData[key] = [];
        }
        groupedData[key].push(term);
      });
      const sections = Object.entries(groupedData).map(group => {
        if (group[1].length > 1) {
          return {
            name: group[0],
            definition: '',
            images: [],
            children: group[1].map(each => {
              return { definition: each.definition, images: each.images }
            })
          }
        }
        return {
          id: group[1][0].id,
          name: group[0],
          definition: group[1][0].definition,
          images: group[1][0].images,
        }
      })
      setSectionList(sections)
    }
  }, [terms]);

  return (
    <>
      <ContentBreadcrumbs stacks='Dashboard/Symptoms/Shortness of Breath' onNavigate={onNavigate} />
      <SectionListLayout>
        <OverviewSection 
          images={title ? title?.images.map(image => image.url) : []} 
          name={title && parse(title?.title)} 
          shortDesc={title && title?.desc && title?.desc !== '' && parse(title?.desc)}
        />
        {sectionList && sectionList.length > 0 && sectionList.map((section, index) => {
          if (index !== sectionList.length - 2 && section.id && sectionList[index + 1] && sectionList[index + 1]?.id) {
            return (
              <CustomSectionContainer
                key={index}
                contentStyle={{ flexDirection: 'row', flexWrap: 'wrap', gap: { xs: 2.5, md: 0 } }}
              >
                {[...Array(2).keys()].map((num => (
                  <OverviewAnnotation
                    key={num}
                    title={parse(sectionList[index + num].name)}
                    titleStyle={{ mb: 2.5 }}
                    content={parse(sectionList[index + num].definition, parsingOptions)}
                    contentStyle={{ gap: 1.5 }}
                    sx={{ flexBasis: { xs: '100%', md: 0 }}}
                  />
                )))}
              </CustomSectionContainer>
            )
          }
          if (section.children) {
            return (
              <CustomSectionContainer
                key={index}
                withHeading
                heading={parse(section.name)}
                headingStyle={{ textAlign: 'center' }}
              >
                <Grid container spacing={2.5}>
                  {section?.children && section?.children.map((child, index) => (
                    <Grid key={index} item xs={12}>
                      <ItemWithImage 
                        image={child.images[0]?.url || ''} 
                        text={parse(child.definition)} 
                        imageSize={120} 
                      />
                    </Grid>
                  ))}
                </Grid>
              </CustomSectionContainer>
            )
          }
          if (index + 2 === sectionList.length && section.id && sectionList[index + 1] && sectionList[index + 1]?.id) {
            return (
              <CustomSectionContainer key={index} contentStyle={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                {[...Array(2).keys()].map((num => (
                  <SubcatContainer 
                    key={num} 
                    withHeading 
                    heading={parse(sectionList[index + num].name)}
                    containerStyle={{ my: 0, flexBasis: { xs: '50%', md: '0' } }}
                    headingStyle={{ textAlign: 'center' }}
                  >
                    {parse(sectionList[index + num].definition, {
                      replace(domNode) {
                        if (domNode instanceof Element && domNode.name === 'ul') {
                          return <>{domToReact(domNode.children, {
                            replace(child) {
                              if (child instanceof Element && child.name === 'li') {
                                return <>{domToReact(child.children, {
                                    replace(childNode) {
                                      if (childNode instanceof Element && childNode.name === 'div') {
                                        return (
                                          <DivContainer>
                                            <PlainTextItem>{domToReact(childNode.children)}</PlainTextItem>
                                          </DivContainer>
                                        )
                                      }
                                    },
                                  })}</>
                                }},
                          })}</>
                        }},
                    })}
                  </SubcatContainer>
                )))}
              </CustomSectionContainer>
            )
          }
        })}
      </SectionListLayout>
    </>
  );
}

export default ShortnessOfBreath;
