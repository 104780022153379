import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";

import ContentBreadcrumbs from "./ContentBreadcrumb.web";
import OverviewSection from "./OverviewSection.web";
import ItemWithImage from "./ItemWithImage.web";
import PlainTextItem from "./PlanTextItem.web";
import { CustomSectionContainer, SectionListLayout, SubcatContainer } from "./CustomContainer.web";
import { ISectionData } from "./SymptomARD.web";
import ReactHtmlParser, { extractTextNodeContent } from "./ReactHtmlParser";

import { IContentData, ITermDetail } from "../../blocks/contentmanagement/src/ContentManagementController";

const HospiceExpectation = ({ data, onNavigate }: { data: IContentData, onNavigate: (to: string) => void }) => {
  const { attributes } = data;
  const { title, terms } = attributes;

  const [sectionList, setSectionList] = useState<ISectionData[]>([]);

  useEffect(() => {
    if (terms) {
      terms.sort((a, b) => a.id - b.id); 
      const groupedData: { [key: string]: ITermDetail[] } = {};
      terms.forEach(term => {
        const key = term.term;
        if (!groupedData[key]) {
          groupedData[key] = [];
        }
        groupedData[key].push(term);
      });
      const sections = Object.entries(groupedData).map(group => {
        if (group[1].length > 1) {
          return {
            name: group[0],
            definition: '',
            images: [],
            children: group[1].map(each => {
              return { definition: each.definition, images: each.images }
            })
          }
        }
        return {
          name: group[0],
          definition: group[1][0].definition,
          images: group[1][0].images,
        }
      })
      setSectionList(sections)
    }
  }, [terms]);

  const renderSectionItem = (htmlString: string, withStyle?: boolean) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const listItems = Array.from(doc.querySelectorAll('li')).map(li => li.innerHTML);
    return listItems.map((item, index) => (<PlainTextItem key={index} style={withStyle ? { padding: '10px 20px '} : undefined}>{ReactHtmlParser(item)}</PlainTextItem>))
  }

  return (
    <Box>
      <ContentBreadcrumbs stacks='Dashboard/The first week in Hospice/What to expect' onNavigate={onNavigate} />
      <SectionListLayout styles={{ gap: '10px' }}>
        <OverviewSection 
          images={title ? title?.images.map(image => image.url) : []} 
          name={title && ReactHtmlParser(title?.title)} 
          shortDesc={title && title?.desc && title?.desc !== '' && ReactHtmlParser(title?.desc)}
        />
         {sectionList
          .filter(section => section.children && section.children?.length > 0)
          .map((section, index) => 
            <CustomSectionContainer key={index} withHeading={false}>
              <Grid container spacing={2.5}>
                {section && section?.children && section?.children.map((child, index) => (
                  <Grid key={index} item xs={12}>
                    <ItemWithImage key={`grid-item-${index}`} image={child.images[0].url || ''} text={ReactHtmlParser(child.definition)} imageSize={120}/>
                  </Grid>
                ))}
              </Grid>
            </CustomSectionContainer>
        )}
        <CustomSectionContainer contentStyle={{ flexDirection: 'row', gap: '20px' }}>
          {sectionList
            .filter(section => extractTextNodeContent(section.name) === 'The Hospice Team consists of a number of different professionals with different skills and responsibilities' 
              || extractTextNodeContent(section.name) === 'What Hospice is NOT')
            .map((section, index) => 
                <SubcatContainer
                  key={index}
                  containerStyle={{ paddingLeft: '20px', paddingRight: '20px', gap: 0 }}
                  withHeading
                  heading={ReactHtmlParser(section.name)}
                  headingStyle={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', bgcolor: '#D6DCD5', height: '116px', textAlign: 'center', p: 1.25, mb: 2.5 }}
                >
                  {renderSectionItem(section.definition, true)}
                </SubcatContainer>
              )}
        </CustomSectionContainer>
        <CustomSectionContainer contentStyle={{ flexDirection: 'row', gap: '20px' }}>
          {sectionList
            .filter(section => extractTextNodeContent(section.name) === 'It is the responsibility of your hospice to provide everything that is needed for comfort and care:' 
              || extractTextNodeContent(section.name) === 'It is not the responsibility of the Hospice Team to provide')
            .map((section, index) => 
              <SubcatContainer
                key={index}
                containerStyle={{ marginTop: 0, marginBottom: 0, paddingLeft: '20px', paddingRight: '20px', gap: 0 }}
                withHeading
                heading={ReactHtmlParser(section.name)}
                headingStyle={{ textAlign: 'center', mb: 2.5 }}
              >
                {renderSectionItem(section.definition, true)}
              </SubcatContainer>)}
        </CustomSectionContainer>
        {sectionList
          .filter(section => extractTextNodeContent(section.name) === '')
          .map((section, index) => 
            <CustomSectionContainer 
              key={index} 
              containerStyle={{ backgroundColor: '#FEF2F2', border: '2px dashed #F87171', padding:'20px 150px !important' }}
            >
              {renderSectionItem(section.definition)}
            </CustomSectionContainer>
        )}
      </SectionListLayout>
    </Box>
  );
}

export default HospiceExpectation;
