import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { styled } from '@mui/system';
import parse, { domToReact, Element, HTMLReactParserOptions } from 'html-react-parser';

import ContentBreadcrumbs from "./ContentBreadcrumb.web";
import OverviewSection from "./OverviewSection.web";
import PlainTextItem from "./PlanTextItem.web";
import { OrderedListItem } from "./ItemWithLetter.web";
import ItemWithImage from "./ItemWithImage.web";
import { ISectionData } from "./SymptomARD.web";
import { CustomSectionContainer, SectionListLayout } from "./CustomContainer.web";
import ReactHtmlParser, { extractTextNodeContent } from "./ReactHtmlParser";

import { IContentData, ITermDetail } from "../../blocks/contentmanagement/src/ContentManagementController";

const NormalText = styled(Box)({
  fontSize: '22px', 
  fontWeight: '400',
  fontFamily: 'Lato',
  color: '#343C32',
  '& p': {
    margin: 0
  }
});

const Image = styled('img')({
  width: '120px', 
  height: '120px',
  objectFit: 'contain'
});

const HeadingContainer = styled(Box)({
  fontSize: '26px', 
  fontWeight: '700',
  fontFamily: 'Lato',
  color: '#586554',
  '& p': {
    margin: 0
  }
});

const Bathing = ({ data, onNavigate }: { data: IContentData, onNavigate: (to: string) => void }) => {
  const { attributes } = data;
  const { title, terms } = attributes;

  const options: HTMLReactParserOptions = {
    replace(domNode) {
      if (domNode instanceof Element && domNode.name === 'ul') {
        return (
          <Box sx={{ mt: 1.25, display: 'flex', flexDirection: 'column', gap: '12px' }}>
            {domToReact(domNode.children, options)}
          </Box>
        )
      }
      if (domNode instanceof Element && domNode.name === 'li') {
        return (
          <PlainTextItem>{domToReact(domNode.children, options)}</PlainTextItem>
        )
      }
    }
  }

  const [sectionList, setSectionList] = useState<ISectionData[]>([]);

  useEffect(() => {
    if (terms) {
      terms.sort((a, b) => a.id - b.id); 
      const groupedData: { [key: string]: ITermDetail[] } = {};
      terms.forEach(term => {
        const key = term.term;
        if (!groupedData[key]) {
          groupedData[key] = [];
        }
        groupedData[key].push(term);
      });
      const sections = Object.entries(groupedData).map(group => {
        if (group[1].length > 1) {
          return {
            name: group[0],
            definition: '',
            images: [],
            children: group[1].map(each => {
              return { definition: each.definition, images: each.images }
            })
          }
        }
        return {
          id: group[1][0].id,
          name: group[0],
          definition: group[1][0].definition,
          images: group[1][0].images,
        }
      })
      setSectionList(sections)
    }
  }, [terms]);

  const renderSectionItems = (htmlString: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const listItems = Array.from(doc.querySelectorAll('li'));
    if (listItems.length > 0) {
      const uTag = doc.querySelector('ul');
      if (uTag?.nextElementSibling && uTag?.nextElementSibling?.tagName.toLocaleLowerCase() === 'p') {
        return (
          <>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
              {listItems.map((item, index) => (<NormalText key={index}>{ReactHtmlParser(item.innerHTML)}</NormalText>))}
            </Box>
            <NormalText>{ReactHtmlParser(uTag?.nextElementSibling?.innerHTML)}</NormalText>
          </>
        )
      }
      return listItems.map((item, index) => (<PlainTextItem key={index}>{ReactHtmlParser(item.innerHTML)}</PlainTextItem>))
    } 
    return <NormalText sx={{ '& p:not(:last-child)': { mb: 1.25 }}}>{ReactHtmlParser(htmlString)}</NormalText>;
  }

  return (
    <>
      <ContentBreadcrumbs stacks='Dashboard/Basic Care/Bathing' onNavigate={onNavigate} />
      <SectionListLayout>
        <OverviewSection 
          images={title ? title?.images.map(image => image.url) : []} 
          name={title && ReactHtmlParser(title?.title)} 
          shortDesc={title && title?.desc && title?.desc !== '' && ReactHtmlParser(title?.desc)}
          containerStyle={{ backgroundColor: 'transparent' }} 
        />
        <CustomSectionContainer containerStyle={{ paddingY: 2.5 }}>
          {sectionList
            .filter(section => section.images.length > 0 
              || (!section.children 
                && (extractTextNodeContent(section.name) === '' 
                || extractTextNodeContent(section.name) === 'What Supplies Do I Need for a Bed Bath?')))
            .map((section, index) => (
              <CustomSectionContainer
                key={index}
                withHeading={section.name !== '' && section.images.length === 0}
                heading={ReactHtmlParser(section.name)}
                containerStyle={{ padding: '0 !important' }}
              >
                {section.images.length > 0 ? 
                  <Box sx={{ display: 'flex', gap: 1.25, alignItems: 'center' }}>
                    <Image src={section.images[0].url} alt={section.name} />
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
                      <HeadingContainer>{ReactHtmlParser(section.name)}</HeadingContainer>
                      <NormalText>{ReactHtmlParser(section.definition)}</NormalText>
                    </Box>
                  </Box> : renderSectionItems(section.definition)
                }
              </CustomSectionContainer>
            ))
          }
        </CustomSectionContainer>
        <CustomSectionContainer containerStyle={{ paddingY: 2.5 }}>
          {sectionList
            .filter(section => section.children && section.children.length > 0)
            .map((section, index) => (
              <CustomSectionContainer
                key={index}
                withHeading={section.name !== ''}
                heading={ReactHtmlParser(section.name)}
                containerStyle={{ padding: '0 !important' }}
                contentStyle={{ marginTop: '6px' }}
              >
                {section.children && section.children.map((child, index) => {
                  if (child.images.length === 0) {
                    return <NormalText>{ReactHtmlParser(child.definition)}</NormalText>
                  }
                  return (
                    <OrderedListItem order={(index).toString()}>
                      <ItemWithImage 
                        image={child.images[0]?.url}
                        imageSize={120} 
                        text={parse(child.definition, options)}
                        style={{ background: 'transparent', borderRadius: '8px', border: 'none' }}
                        textStyle={{ maxHeight: 'fit-content' }}
                      />
                    </OrderedListItem>
                  )})}
              </CustomSectionContainer>
            ))
          }
        </CustomSectionContainer>
        {sectionList
          .filter(section => section.images.length === 0 && !section.children && extractTextNodeContent(section.name) !== '' 
            && extractTextNodeContent(section.name) !== 'What Supplies Do I Need for a Bed Bath?')
          .map((section, index) => (
            <CustomSectionContainer
              key={index}
              withHeading={extractTextNodeContent(section.name).length > 1}
              heading={ReactHtmlParser(section.name)}
            >
              {renderSectionItems(section.definition)}
            </CustomSectionContainer>
          ))
        }
      </SectionListLayout>
    </>
  );
}

export default Bathing;
