import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";

import ContentBreadcrumbs from "./ContentBreadcrumb.web";
import OverviewSection from "./OverviewSection.web";
import ItemWithImage from "./ItemWithImage.web";
import ItemWithLetter from "./ItemWithLetter.web";
import PlainTextItem from "./PlanTextItem.web";
import { OverviewAnnotation } from "./MethodOverviewSection.web";
import { CustomSectionContainer, SectionListLayout } from "./CustomContainer.web";
import { ISectionData } from "./SymptomARD.web";
import ReactHtmlParser, { extractTextNodeContent } from "./ReactHtmlParser";

import { IContentData, ITermDetail } from "../../blocks/contentmanagement/src/ContentManagementController";

const MouthCare = ({ data, onNavigate }: { data: IContentData, onNavigate: (to: string) => void }) => {
  const { attributes } = data;
  const { title, terms } = attributes;

  const [sectionList, setSectionList] = useState<ISectionData[]>([]);

  useEffect(() => {
    if (terms) {
      terms.sort((a, b) => a.id - b.id); 
      const groupedData: { [key: string]: ITermDetail[] } = {};
      terms.forEach(term => {
        const key = term.term;
        if (!groupedData[key]) {
          groupedData[key] = [];
        }
        groupedData[key].push(term);
      });
      const sections = Object.entries(groupedData).map(group => {
        if (group[1].length > 1) {
          return {
            name: group[0],
            definition: '',
            images: [],
            children: group[1].map(each => {
              return { definition: each.definition, images: each.images }
            })
          }
        }
        return {
          name: group[0],
          definition: group[1][0].definition,
          images: group[1][0].images,
        }
      })
      setSectionList(sections)
    }
  }, [terms]);

  const renderSectionItems = (htmlString: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const itemWithLetterList = Array.from(doc.querySelectorAll('li:has(div)')).map(li => {
      return {
        letter: (li.children[0] && li.children[0]?.tagName === 'DIV' && li.children[0]?.textContent) || '',
        content: li.children[1] && li.children[1]?.tagName === 'P' ? [li.children[1].innerHTML] : [],
      }
    })
    if (itemWithLetterList.length > 0) {
      return itemWithLetterList.map((item, index) => <ItemWithLetter key={index} {...item} />)
    }
    const listItems = Array.from(doc.querySelectorAll('li'));
    if (listItems.length > 0) {
      return listItems.map((item, index) => (<PlainTextItem key={index}>{ReactHtmlParser(item.innerHTML)}</PlainTextItem>))
    } 
    return ReactHtmlParser(htmlString);
  }
  const renderItemWithAnnotation = (htmlString: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    if (doc.querySelector('p + ul')) {
      const pTag = doc.querySelector('p');
      return <OverviewAnnotation 
        title={pTag && ReactHtmlParser(pTag?.innerHTML)} 
        content={pTag && pTag?.nextElementSibling && renderSectionItems(pTag?.nextElementSibling?.innerHTML)}
        layoutStyle={{ mt: '-20px' }}
        contentStyle={{ gap: '12px' }} 
      />
    } 
    return ReactHtmlParser(htmlString);
  }

  return (
    <>
      <ContentBreadcrumbs stacks='Dashboard/Symptoms/Mouth Care' onNavigate={onNavigate} />
      <SectionListLayout styles={{ gap: 0 }}>
        <OverviewSection 
          images={title ? title?.images.map(image => image.url) : []} 
          name={title && ReactHtmlParser(title?.title)} 
          shortDesc={title && title?.desc && title?.desc !== '' && ReactHtmlParser(title?.desc)}
          containerStyle={{ backgroundColor: 'transparent' }}
        />
        <CustomSectionContainer variant="light" containerStyle={{ paddingY: 0 }} contentStyle={{ gap: 0 }}>
          {sectionList.map((section, index) => (
            <CustomSectionContainer 
              key={index}
              containerStyle={{ bgcolor: section.children && '#F2F4F1', padding: index !== (sectionList.length - 1) ? '40px 20px 0 !important' : '40px 20px !important' }}
              withHeading={extractTextNodeContent(section.name) !== ''}
              heading={extractTextNodeContent(section.name) !== '' && ReactHtmlParser(section.name)}
              contentStyle={{ marginTop: index === 1 ? '20px' : '40px', gap: index === 1 ? '20px' : '40px' }}
            >
              {section.children && section.children.length > 0 ? 
                <Grid container spacing={2.5}> 
                  {section?.children && section?.children.map((child, index) => (
                    <Grid key={index} item xs={12} md={React.Children.count(ReactHtmlParser(child.definition)) === 2 && 6}>
                      <ItemWithImage image={child.images[0].url} text={renderItemWithAnnotation(child.definition)} />
                    </Grid>
                  ))}                                                                                                         
                </Grid> : renderSectionItems(section.definition)
              }
            </CustomSectionContainer>
          ))}
        </CustomSectionContainer>
      </SectionListLayout>
    </>
  );
}

export default MouthCare;
