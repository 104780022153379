// Customizable Area Start

import React, { useEffect, useState } from 'react';

import ContentBreadcrumbs from './ContentBreadcrumb.web';
import OverviewSection from './OverviewSection.web';
import PlainTextItem from './PlanTextItem.web';
import { OrderedListItem } from './ItemWithLetter.web';
import { OverviewAnnotation } from './MethodOverviewSection.web';
import { CustomSectionContainer, SectionListLayout } from './CustomContainer.web';
import { ISectionData } from './SymptomARD.web';
import ReactHtmlParser from './ReactHtmlParser';

import { IContentData, ITermDetail } from "../../blocks/contentmanagement/src/ContentManagementController";

export default function StopMedication({ data, onNavigate }: { data: IContentData, onNavigate: (to: string) => void }) {
  const { attributes } = data;
  const { title, terms } = attributes;

  const [sectionList, setSectionList] = useState<ISectionData[]>([]);

  useEffect(() => {
    if (terms) {
      terms.sort((a, b) => a.id - b.id); 
      const groupedData: { [key: string]: ITermDetail[] } = {};
      terms.forEach(term => {
        const key = term.term;
        if (!groupedData[key]) {
          groupedData[key] = [];
        }
        groupedData[key].push(term);
      });
      const sections = Object.entries(groupedData).map(group => {
        if (group[1].length > 1) {
          return {
            name: group[0],
            definition: '',
            images: [],
            children: group[1].map(each => {
              return { definition: each.definition, images: each.images }
            })
          }
        }
        return {
          id: group[1][0].id,
          name: group[0],
          definition: group[1][0].definition,
          images: group[1][0].images,
        }
      })
      setSectionList(sections)
    }
  }, [terms]); 

  const renderSectionItems = (htmlString: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    return Array.from(doc.querySelectorAll('li')).map((li, index) => (<PlainTextItem key={index}>{ReactHtmlParser(li.innerHTML)}</PlainTextItem>))
  }
  
  return (
    <>
      <ContentBreadcrumbs stacks='Dashboard/Medication/Stopping Medications' onNavigate={onNavigate} />
      <SectionListLayout styles={{ gap: 1.25, backgroundColor: '#F2F4F1', borderRadius: '8px' }}>
        <OverviewSection 
          images={title ? title?.images.map(image => image.url) : []} 
          name={title && ReactHtmlParser(title?.title)} 
          shortDesc={title && title?.desc && title?.desc !== '' && ReactHtmlParser(title?.desc)}
        />
        <CustomSectionContainer containerStyle={{ pt: 0 }} contentStyle={{ gap: '40px' }}>
          {sectionList.map((section, index) => (
            <OrderedListItem key={index} order={(index + 1).toString()} styles={{ alignItems: 'flex-start' }}>
              <OverviewAnnotation 
                title={ReactHtmlParser(section.name)} 
                content={renderSectionItems(section.definition)} 
                sx={{ paddingX: 2.5 }}
                layoutStyle={{ mt: '-12px' }}
              />
            </OrderedListItem>
          ))}
        </CustomSectionContainer>
      </SectionListLayout>
    </>
  )
}

// Customizable Area End


