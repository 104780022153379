import React, { CSSProperties, isValidElement, ReactNode } from 'react';
import { Box, Grid } from '@mui/material';
import { styled, SxProps } from '@mui/system';

const MainContainer = styled(Box)({
  backgroundColor: "#F2F4F1", 
  color: '#343C32',
  borderRadius: "8px", 
});

const TitleContainer = styled(Box)({
  fontWeight: '700',
  fontFamily: 'Lato',
  color: '#343C32',
  '& p': {
    margin: 0
  }
});

const SubtitleContainer = styled(TitleContainer)({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  fontWeight: '400', 
  color: '#586554'
});

const SubtitleList = styled(Box)({
  '& ul': {
    padding: 0,
    display: 'flex', 
    flexDirection: 'column', 
    gap: '10px',
    listStyle: 'none',
    '& li': {
      borderRadius: '8px',
      backgroundColor: '#F2F4F1',
      padding: '20px 0'
    }
  }
});

const DescImg = styled('img')({
  width: '120px',
  height: '120px',
  objectFit: 'contain'
});

type IOverviewSectionProps = {
  images: string[];
  name: ReactNode;
  shortDesc: ReactNode;
  annotation?: ReactNode;
  containerStyle?: SxProps; 
  annotationStyle?: CSSProperties; 
}

const OverviewSection = (props: IOverviewSectionProps) => (
  <MainContainer sx={{ padding: { xs: "40px 16px", md: '40px 130px' }, ...props.containerStyle }}>
    <Grid container alignItems="center" spacing={2}>
      {props.images.length > 0 && <Grid item xs={12} md={2} container justifyContent="center">
        <DescImg sx={{ display: { xs: 'none', md: 'block' } }} src={props.images[0]} alt='Left image' />
      </Grid>}
      <Grid item xs={12} md={props.images.length > 0 ? 8 : 12}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, textAlign: 'center' }}>
          <TitleContainer sx={{ fontSize: { xs: 20, md: 26 } }}>{props.name}</TitleContainer>
          {props.shortDesc && 
            <SubtitleContainer sx={{ fontSize: { xs: 16, md: 22 } }}>
              {isValidElement(props.shortDesc) && props.shortDesc.type !== 'ul' ? props.shortDesc :
                <SubtitleList>{props.shortDesc}</SubtitleList>
              }
            </SubtitleContainer>} 
        </Box>
      </Grid>
      {props.images.length > 0 && <Grid item xs={12} md={2} container justifyContent="center">
        <DescImg sx={{ display: { xs: 'none', md: 'block' } }} src={props.images[1]} alt='Right image' />
      </Grid>}
    </Grid>
    {props.annotation && <Box mt={5} sx={{ ...props.annotationStyle }}>{props.annotation}</Box>}
  </MainContainer>
);

export default OverviewSection;
