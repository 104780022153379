Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.patchAPiMethod = "PATCH";
exports.deleteAPiMethod = "DELETE";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "settings2";
exports.labelBodyText = "settings2 Body";
exports.apiDeleteAccountUrl = "bx_block_account/hospice";
exports.apiDeleteHospiceAccountUrl = "account_block/accounts/delete_account";
exports.apiGetCurrentHospiceUrl = "account_block/accounts";
exports.apiRemoveLogoUrl = "account_block/accounts/remove_logo";
exports.apiUpdateEmailLogoUrl = "account_block/accounts/update_email";
exports.apiUpdatePasswordUrl = "bx_block_forgot_password/passwords/change_password";
exports.subscriptionEndpoint = "bx_block_subscription_billing/products"
exports.mySubscriptionEndpoint = "bx_block_stripe_integration/subscriptions/get_subscriptions"
exports.cancelMySubscriptionEndpoint = "bx_block_stripe_integration/subscriptions/cancel_subscription"
exports.changeMySubscriptionEndpoint = "bx_block_stripe_integration/subscriptions/upgrade_downgrade_subscription"
exports.continueMySubscriptionEndpoint = "bx_block_stripe_integration/subscriptions/continue_subscription"
exports.cancelUpgradeDowngradeEndpoint = "bx_block_stripe_integration/subscriptions/cancel_downgrade_upgrade_subscription"
exports.billingHistoryEndpoint = "bx_block_stripe_integration/subscriptions/subscriptions_history"
exports.listOfStates = [
  "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA", 
  "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD", 
  "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", 
  "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC", 
  "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"
];
exports.errorMessageHospiceName= "Enter your hospice name"
exports.errorMessageContactNumber= "Enter contact number"
exports.errorMessageWebsite= "Enter the URL to your website"
exports.errorMessageAddress= "Enter valid address"
exports.errorMessageCity= "Enter valid city"
exports.errorMessageSelectedState= "Select state"
exports.errorMessagePostalCode= "Enter valid zip code"
exports.errorMessageHospiceLogo= "One file allowed. Acceptable file types include .png, .jpg, .jpeg."
exports.regexContactNumber= /^\d{10}$/
exports.btnExampleTitle = "CLICK ME";
exports.formTitle = "Hospice Information"
exports.labelHospiceName= "Hospice Name*"
exports.labelContactNumber= "Contact Number for Patients to Reach You*"
exports.labelWebsite= "Website*"
exports.labelLicense= "License"
exports.labelAddress= "Address*"
exports.labelCity= "City*"
exports.labelSelectedState= "State*"
exports.labelPostalCode= "Zip Code*"
exports.placeholderHospiceName= "Hospice Name"
exports.placeholderContactNumber= "Enter contact number"
exports.placeholderWebsite= "Enter website URL"
exports.placeholderLicense= "Enter license"
exports.placeholderAddress= "Enter address"
exports.placeholderCity= "Enter city"
exports.placeholderSelectedState= "Select State"
exports.placeholderPostalCode= "Enter zip code"
exports.placeholderHospiceLogo= "Upload your logo"
exports.titleYourHospiceLogo= "Your Hospice Logo"
exports.titleDeleteAccount= "Delete Account"
exports.btnUploadLogo = "Upload Logo"
exports.btnRemoveLogo = "Remove Logo"
exports.btnTxtSave = "Save"
exports.btnTxtCancel = "Cancel"
exports.btnDeleteAccount = "Delete Account"
exports.deleteAccountDescription = "Once requested, your account will be pending deletion for 14 days. Simply log in at any time during this 14-day period and the deletion process will be stopped."
exports.labelPassword = "Enter Password"
exports.labelConfirmPassword = "Confirm Password"
exports.labelComment = "Comment"
exports.placeholderPassword = "Your password"
exports.placeholderConfirmPassword = "Confirm your password"
exports.placeholderComment = "Please tell us why you are deleting your account"
exports.buttonDeleteAccount = "Delete Account"
exports.headerDeleteAccount = "Delete Account"
exports.errorMessagePassword = "Enter your password"
exports.errorMessageConfirmPassword = "Enter confirm your password"
exports.errorMessageComment = "Please provide the reason for deleting your account. We appreciate your honest feedback."
exports.headerModalTitle = "Delete Account"
exports.modalFirstLine = "Are you sure you want to delete your account permanently?"
exports.modalSecondLine = "Once requested, your account will be pending deletion for 14 days. Simply log in at any time during this 14-day period and the deletion process will be stopped"
exports.titleChangeEmail= "Change Email"
exports.labelEmail = "Email Address"
exports.labelConfirmEmail = "Confirm New Email"
exports.errorMessageEmail= "Enter valid email"
exports.placeHolderEmail = "Your new email";
exports.placeHolderConfirmEmail = "Confirm your new email";
exports.textChangeEmailButton = "Change Email"
exports.labelOldPassword = "Current Password"
exports.labelConfirmPassword = "Confirm New Password"
exports.labelNewPassword = "New Password"
exports.placeHolderOldPassword = "Your old password"
exports.placeHolderNewPassword = "Your new password"
exports.placeHolderConfirmPassword = "Confirm your new password"
exports.textChangePasswordButton = "Change Password"
exports.errorMessagePasswordMatch = "Password should be at least 8 characters long and contain at least 1 special character and 1 uppercase letter and 1 number"
// Customizable Area End