import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";

import ContentBreadcrumbs from "./ContentBreadcrumb.web";
import PlainTextItem from "./PlanTextItem.web";
import { CustomSectionContainer, SectionListLayout } from "./CustomContainer.web";
import { ISectionData } from "./SymptomARD.web";
import ReactHtmlParser from "./ReactHtmlParser";

import { IContentData, ITermDetail } from "../../blocks/contentmanagement/src/ContentManagementController";

const DryMouthCare = ({ data, onNavigate }: { data: IContentData, onNavigate: (to: string) => void }) => {
  const { attributes } = data;
  const { terms } = attributes;

  const [sectionList, setSectionList] = useState<ISectionData[]>([]);

  useEffect(() => {
    if (terms) {
      terms.sort((a, b) => a.id - b.id); 
      const groupedData: { [key: string]: ITermDetail[] } = {};
      terms.forEach(term => {
        const key = term.term;
        if (!groupedData[key]) {
          groupedData[key] = [];
        }
        groupedData[key].push(term);
      });
      const sections = Object.entries(groupedData).map(group => {
        if (group[1].length > 1) {
          return {
            name: group[0],
            definition: '',
            images: [],
            children: group[1].map(each => {
              return { definition: each.definition, images: each.images }
            })
          }
        }
        return {
          name: group[0],
          definition: group[1][0].definition,
          images: group[1][0].images,
        }
      })
      setSectionList(sections)
    }
  }, [terms]); 

  const renderSectionItem = (htmlString: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const listItems = Array.from(doc.querySelectorAll('li')).map(li => li.innerHTML);
    return listItems.map((item, index) => (
      <PlainTextItem key={index} style={{ backgroundColor: index % 2 === 0 ? 'transparent' : '#F2F4F1', paddingTop: '12px', paddingBottom: '12px' }}>
        {ReactHtmlParser(item)}
      </PlainTextItem>
    ))
  }
  
  return (
    <Box>
      <ContentBreadcrumbs stacks='Dashboard/Symptoms/Mouth Care/Dry Mouth Medications' onNavigate={onNavigate} />
      <SectionListLayout styles={{ gap: '10px' }}>
        {sectionList && sectionList.length > 0 && sectionList.map(section => 
          <CustomSectionContainer variant="light" containerStyle={{ padding: "40px 110px 20px !important" }}>
            <CustomSectionContainer 
              withHeading={!!section.name}
              heading={ReactHtmlParser(section.name)}
              headingStyle={{ textAlign: 'center' }}
              variant="light"
              containerStyle={{ border: "1px solid #D6DCD5", paddingTop: "20px", paddingBottom: "20px", paddingLeft: "20px !important", paddingRight: "20px !important" }}
              contentStyle={{ gap: 0 }}
            >
              {renderSectionItem(section.definition)}
            </CustomSectionContainer>
          </CustomSectionContainer>)}
      </SectionListLayout>
    </Box>
  );
}

export default DryMouthCare;
