import React, { useEffect, useState } from 'react';

import ContentBreadcrumbs from './ContentBreadcrumb.web';
import ItemWithImage from './ItemWithImage.web';
import { ISectionData } from './SymptomARD.web';
import { CustomSectionContainer, SubcatContainer } from './CustomContainer.web';
import ReactHtmlParser from './ReactHtmlParser';

import { IContentData, ITermDetail } from "../../blocks/contentmanagement/src/ContentManagementController";

export default function OpioidMyths({ data, onNavigate }: { data: IContentData, onNavigate: (to: string) => void }) {
  const { attributes } = data;
  const { terms } = attributes;

  const [sectionList, setSectionList] = useState<ISectionData[]>([]);

  useEffect(() => {
    if (terms) {
      terms.sort((a, b) => a.id - b.id); 
      const groupedData: { [key: string]: ITermDetail[] } = {};
      terms.forEach(term => {
        const key = term.term;
        if (!groupedData[key]) {
          groupedData[key] = [];
        }
        groupedData[key].push(term);
      });
      const sections = Object.entries(groupedData).map(group => {
        if (group[1].length > 1) {
          return {
            name: group[0],
            definition: '',
            images: [],
            children: group[1].map(each => {
              return { definition: each.definition, images: each.images }
            })
          }
        }
        return {
          name: group[0],
          definition: group[1][0].definition,
          images: group[1][0].images,
        }
      })
      setSectionList(sections)
    }
  }, [terms]);

  return (
    <>
      <ContentBreadcrumbs stacks='Dashboard/Medications/Opioid Myths' onNavigate={onNavigate} />
      <CustomSectionContainer contentStyle={{ flexDirection: 'row', flexWrap: 'wrap', columnGap: 2.5  }}>
        {sectionList.map((section, index) => 
          <SubcatContainer
            key={index}
            withHeading
            heading={ReactHtmlParser(section.name)}
          >
            {section.children && section.children.map((item, index) => 
              <ItemWithImage 
                key={index} 
                image={item.images[0]?.url} 
                text={ReactHtmlParser(item.definition)} 
                textStyle={{ maxHeight: 'fit-content' }}
                imageSize={24}  
              />)}
          </SubcatContainer>)}
      </CustomSectionContainer>
    </>
  );
}
