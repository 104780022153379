import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { styled } from '@mui/system';

import ContentBreadcrumbs from "./ContentBreadcrumb.web";
import OverviewSection from "./OverviewSection.web";
import ItemWithImage from "./ItemWithImage.web";
import PlainTextItem from "./PlanTextItem.web";
import { ISectionData } from "./SymptomARD.web";
import { CustomSectionContainer, SectionListLayout } from "./CustomContainer.web";
import ReactHtmlParser from "./ReactHtmlParser";

import { IContentData, ITermDetail } from "../../blocks/contentmanagement/src/ContentManagementController";

const NormalText = styled(Box)({
  fontSize: '22px', 
  fontWeight: '400',
  fontFamily: 'Lato',
  color: '#343C32',
  '& p': {
    margin: 0
  }
});

const BelchingAndHiccups = ({ data, onNavigate }: { data: IContentData, onNavigate: (to: string) => void }) => {
  const { attributes } = data;
  const { title, terms } = attributes;

  const [sectionList, setSectionList] = useState<ISectionData[]>([]);

  useEffect(() => {
    if (terms) {
      terms.sort((a, b) => a.id - b.id); 
      const groupedData: { [key: string]: ITermDetail[] } = {};
      terms.forEach(term => {
        const key = term.term;
        if (!groupedData[key]) {
          groupedData[key] = [];
        }
        groupedData[key].push(term);
      });
      const sections = Object.entries(groupedData).map(group => {
        if (group[1].length > 1) {
          return {
            name: group[0],
            definition: '',
            images: [],
            children: group[1].map(each => {
              return { definition: each.definition, images: each.images }
            })
          }
        }
        return {
          name: group[0],
          definition: group[1][0].definition,
          images: group[1][0].images,
        }
      })
      setSectionList(sections)
    }
  }, [terms]);

  const renderList = (htmlString: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const listItems = Array.from(doc.querySelectorAll('li'));
    if (listItems.length > 0) {
      return listItems.map((item, index) => (<PlainTextItem key={index}>{ReactHtmlParser(item.innerHTML)}</PlainTextItem>))
    } 
    return ReactHtmlParser(htmlString);
  }

  return (
    <Box pb={2.5}>
      <ContentBreadcrumbs stacks='Dashboard/Symptoms/Belching and Hiccups' onNavigate={onNavigate} />
      <SectionListLayout>
        <OverviewSection 
          images={title ? title?.images.map(image => image.url) : []} 
          name={title && ReactHtmlParser(title?.title)} 
          shortDesc={title && title?.desc && title?.desc !== '' && ReactHtmlParser(title?.desc)}
          containerStyle={{ backgroundColor: 'transparent', padding: '0 188px' }} 
        />
         {sectionList.map((section, index) => 
          <CustomSectionContainer
            key={index}
            withHeading
            heading={ReactHtmlParser(section.name)}
            variant={index === (sectionList.length - 1) ? "alert" : undefined}
            shrink
            containerStyle={{ backgroundColor: 'transparent', paddingTop: '0', paddingBottom: '0' }}
            innerContainerStyle={{ padding: section.children && section.children.length > 0 ? '40px 130px' : 
              index === (sectionList.length - 1) ? '40px 20px' : '20px 20px' }}
          >
            {section.children && section.children.length > 0 ? 
              <Grid container spacing={2.5}> 
                {section?.children && section?.children.map((child, index) => (
                  <Grid key={index} item xs={12} md={6}>
                    {child.images.length > 0 ? 
                      <ItemWithImage image={child.images[0].url} text={ReactHtmlParser(child.definition)} /> : 
                      <Box py={2.5} sx={{ backgroundColor: '#FFF' }}>
                        <NormalText>
                          {renderList(child.definition)}
                        </NormalText>
                      </Box>}
                  </Grid>
                ))}                                                                                                          
              </Grid> : renderList(section.definition)
            }
          </CustomSectionContainer>)}
      </SectionListLayout>
    </Box>
  );
}

export default BelchingAndHiccups;
