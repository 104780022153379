import React, { useEffect, useState } from "react";
import { Grid, Link } from "@mui/material";
import { styled } from "@mui/system";
import parse, { domToReact, Element, HTMLReactParserOptions } from "html-react-parser";

import ContentBreadcrumbs from "./ContentBreadcrumb.web";
import OverviewSection from "./OverviewSection.web";
import ItemWithImage from "./ItemWithImage.web";
import PlainTextItem from "./PlanTextItem.web";
import { CustomSectionContainer, SectionListLayout } from "./CustomContainer.web";
import { ISectionData } from "./SymptomARD.web";

import { IContentData, ITermDetail } from "../../blocks/contentmanagement/src/ContentManagementController";

const CustomLink = styled(Link)({
  color: '#343C32',
  textDecorationColor: '#343C32'
})

const AppetiteFood = ({ data, onNavigate }: { data: IContentData, onNavigate: (to: string) => void }) => {
  const { attributes } = data;
  const { title, terms } = attributes;

  const parsingOptions: HTMLReactParserOptions = {
    replace(domNode) {
      if (domNode instanceof Element && domNode.name === 'li') {
        return (
          <PlainTextItem>{domToReact(domNode.children, parsingOptions)}</PlainTextItem>
        )
      }
      if (domNode instanceof Element && domNode.name === 'ul') {
        return (
          <>{domToReact(domNode.children, parsingOptions)}</>
        )
      }
      if (domNode instanceof Element && domNode.name === 'a') {
        return (
          <CustomLink href={domNode.attribs['href']} target="_blank">{domToReact(domNode.children, parsingOptions)}</CustomLink>
        )
      }
    },
  }

  const [sectionList, setSectionList] = useState<ISectionData[]>([]);

  useEffect(() => {
    if (terms) {
      terms.sort((a, b) => a.id - b.id); 
      const groupedData: { [key: string]: ITermDetail[] } = {};
      terms.forEach(term => {
        const key = term.term;
        if (!groupedData[key]) {
          groupedData[key] = [];
        }
        groupedData[key].push(term);
      });
      const sections = Object.entries(groupedData).map(group => {
        if (group[1].length > 1) {
          return {
            name: group[0],
            definition: '',
            images: [],
            children: group[1].map(each => {
              return { definition: each.definition, images: each.images }
            })
          }
        }
        return {
          id: group[1][0].id,
          name: group[0],
          definition: group[1][0].definition,
          images: group[1][0].images,
        }
      })
      setSectionList(sections)
    }
  }, [terms]);
  
  return (
    <>
      <ContentBreadcrumbs stacks='Dashboard/Symptoms/Appetite and Food' onNavigate={onNavigate} />
      <SectionListLayout>
        <OverviewSection 
          images={title ? title?.images.map(image => image.url) : []} 
          name={title && parse(title?.title)} 
          shortDesc={title && title?.desc && title?.desc !== '' && parse(title?.desc)}
        />
        {sectionList                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               
          .map((section, index) => {
            if (section.children && section.children.length > 0) {
              return (
                <CustomSectionContainer
                  key={index}
                  withHeading
                  heading={parse(section.name)}
                  containerStyle={{ backgroundColor:  '#FEF2F2', border: '2px dashed #F87171' }} 
                  headingStyle={{ textAlign: 'center' }}
                >
                  <Grid container spacing={2.5}>
                    {section?.children && section?.children.map((child, index) => (
                      <Grid key={index} item xs={12} md={6}>
                        <ItemWithImage image={child.images[0]?.url || ''} text={parse(child.definition)} />
                      </Grid>
                    ))}
                  </Grid>
                </CustomSectionContainer>)}
            if (section.id && sectionList[index + 1] && sectionList[index + 1]?.id && sectionList[index + 2] && sectionList[index + 2]?.id) {
                return (
                  <CustomSectionContainer key={index}>
                    {[...Array(3).keys()].map((num) => {
                      if (num === 2) {
                        return (
                          <CustomSectionContainer
                            key={num}
                            containerStyle={{ mt: 2.5, padding: { xs: '40px 20px' }, backgroundColor:  '#FEF2F2', border: '2px dashed #F87171' }}
                            contentStyle={{ gap: 1.5 }}
                          >
                            {parse(sectionList[index + num].definition, parsingOptions)}
                          </CustomSectionContainer>
                        )
                      }
                      return (
                        <CustomSectionContainer
                          key={num}
                          withHeading
                          heading={parse(sectionList[index + num].name)}
                          containerStyle={{ padding: { xs: 0 } }}
                          contentStyle={{ gap: 1.5 }}
                        >
                          {parse(sectionList[index + num].definition, parsingOptions)}
                        </CustomSectionContainer>)
                    })}
                  </CustomSectionContainer>)} 
          })}
      </SectionListLayout>
    </>
  );
}

export default AppetiteFood;
