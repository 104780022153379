import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  basicCareImg,
  caregiverIcon,
  eolCareImg,
  homeIcon,
  loginIcon,
  medImg,
  nurseImg,
  primaryPhoneIcon,
  symptomImg,
} from "./assets";

// Customizable Area Start
import { ReactNode } from "react";
import { extractTextNodeContent } from "../../../components/src/ReactHtmlParser";

export type ICardProps = {
  thumbnail: string;
  children: ReactNode; 
};
export interface IImage {
  id: number;
  url: string;
  type: string;
  filename: string;
}
export type ITermProps = {
  id: number;
  term: string;
  definition: string;
  images: IImage[];
  children? : { definition: string; images: IImage[] }[];
};

export const navLinks = [
  { icon: homeIcon, label: "home", value: "LandingPage", testId: 'btn-home' },
  { icon: caregiverIcon, label: "about us", value: "AboutUs", testId: 'btn-about-us' },
  { icon: primaryPhoneIcon, label: "contact us", value: "Contactus", testId: 'btn-contact-us' },
  { icon: loginIcon, label: "login", value: "EmailAccountLoginBlock", testId: 'btn-login' },
];

export const serviceList = [
  {
    thumbnail: medImg,
    title: "Medications",
    description:
      "Gain valuable insights into the medications commonly prescribed for hospice patients. Understand their purposes, dosages, potential side effects, and administration guidelines to ensure optimal care and comfort for your loved one.",
  },
  {
    thumbnail: symptomImg,
    title: "Symptom Management",
    description:
      "Navigate through comprehensive resources that offer guidance on managing and alleviating various symptoms your loved one may experience during their hospice journey. From pain management strategies to addressing common discomforts, equip yourself with the knowledge to provide effective symptom relief and enhance your loved one's quality of life.",
  },
  {
    thumbnail: nurseImg,
    title: "When to Call the Nurse",
    description:
      "Navigate through comprehensive resources that offer guidance on managing and alleviating various symptoms your loved one may experience during their hospice journey. From pain management strategies to addressing common discomforts, equip yourself with the knowledge to provide effective symptom relief and enhance your loved one's quality of life.",
  },
  {
    thumbnail: eolCareImg,
    title: "Understanding End-of-Life Care",
    description:
      "Explore sensitive yet essential information about the dying process. Gain insights into what to expect during the final stages of life, including common physical and emotional changes, and discover ways to provide compassionate care and support to your loved one as they approach the end of life.",
  },
  {
    thumbnail: basicCareImg,
    title: "Basic Care Practices",
    description:
      "Acquire fundamental caregiving skills and techniques necessary for providing comfort and assistance to your loved one. From assistance with activities of daily living to maintaining a safe and comforting environment, access practical tips and step-by-step guides to ensure your loved one receives the best possible care at home.",
  },
];

export const quickLinks = [
  { label: "register", value: "EmailAccountRegistration" },
  { label: "about us", value: "AboutUs" },
  { label: "contact us", value: "Contactus" },
  { label: "privacy policy", value: "PrivacyPolicy", testId: 'btn-PP' },
  { label: "terms & conditions", value: "TermsConditions", testId: 'btn-TAC' },
];
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  screenId: string;
  classes?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  videoUrl: string;
  terms: ITermProps[];
  navigationData: ITermProps;
  newsletterData: ITermProps;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  screenId: string;
  // Customizable Area End
}

// Customizable Area Start

// Customizable Area End

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getMediaApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    // Customizable Area End

    this.state = {
      // Customizable Area Start
      loading: false,
      videoUrl: '',
      terms: [],
      navigationData: { id: 0, term: '', definition: '', images: [], children: [{ definition: '', images: [] }] },
      newsletterData: { id: 1, term: '', definition: '', images: [] },
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (errorReponse) {
      this.setState({ loading: true })
      this.parseApiCatchErrorResponse(errorReponse);
      return
    }

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const data = responseJson.data;
    const attributes = (data && data.length > 0 && data[0]?.attributes) || {};
    this.setState({ videoUrl: attributes.video_file || '' })
    if (attributes?.terms) {
      attributes?.terms.sort((a: ITermProps, b: ITermProps) => a?.id - b?.id); 
      const groupedData: { [key: string]: ITermProps[] } = {};
      attributes?.terms.forEach((term: ITermProps) => {
        const key = extractTextNodeContent(term.term);
        if (!groupedData[key]) {
          groupedData[key] = [];
        }
        groupedData[key].push(term);
      });
      const sections = Object.entries(groupedData).map(group => {
        if (group[1].length > 1) {
          return {
            id: group[1][0].id,
            term: group[1][0].term,
            definition: '',
            images: [],
            children: group[1].map(each => {
              return { definition: each.definition, images: each.images }
            })
          }
        }
        return {
          id: group[1][0].id,
          term: group[1][0].term,
          definition: group[1][0].definition,
          images: group[1][0].images,
        }
      })
      this.setState({ 
        terms: sections, 
        navigationData: sections[sections.length - 2], 
        newsletterData: sections[sections.length - 1]
      })
    }
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    this.fetchContentData();
  }

  fetchContentData() {
    const header = { "Content-Type": configJSON.exampleApiContentType };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.fetchingContentApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  naviagteTo(screen: string) {
    if (screen === 'PrivacyPolicy') {
      this.goToPrivacyPolicy();
      return;
    }
    if (screen === 'TermsConditions') {
      this.goToTermsAndCondition();
      return;
    }
    this.props.navigation.navigate(screen);
  }
  goToPrivacyPolicy = () => {
    const navigateToPPMessage: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    navigateToPPMessage.addData(getName(MessageEnum.TermsAndConditionsTypeMessage), "Privacy Policy");
    navigateToPPMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateToPPMessage);
  }
  goToTermsAndCondition = () => {
    const navigateToTACMessage: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    navigateToTACMessage.addData(getName(MessageEnum.TermsAndConditionsTypeMessage), "Terms and Conditions");
    navigateToTACMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateToTACMessage);
  }
  // Customizable Area End
}
