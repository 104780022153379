// Customizable Area Start
import React from "react";

import { Typography } from '@builder/component-library';

import HospiceHeader from "../../../components/src/HospiceHeader";
import {
  Box,
  Typography as MaterialTypography,
  styled,
  IconButton,
  Button as MaterialButton
} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {headerTable}  from './assets'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import moment from 'moment'

import ViewBillingController, {
  IBillingHistory,
  Props,
} from "./ViewBillingController";

export default class ViewBilling extends ViewBillingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  renderTableBillingHistory = () => {
    return      <TableWrapper>
    <TableContainer style={{boxShadow: "none", overflowX: 'auto'}} component={Paper}>
    <Table data-test-id="table-patient-data" sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
        <TableRow>
          {headerTable.map(item => {
            return this.renderTableHeader(item)
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {this.state.listBillingHistory.map(bill => this.renderTableRows(bill))}
      </TableBody>
    </Table>
  </TableContainer>
  </TableWrapper>
  }

  renderStatus = (status: boolean) => {
    if (status === true) {
      return <div style={{
        background: '#D1FAE5',
         borderRadius: 100,
        width: 90,
        padding: 2,
      }}>
        <Typography style={{
          textAlign: 'center',fontFamily: 'Lato', fontWeight: '700', fontSize: 14,color: '#059669'
        }}>Paid</Typography>
      </div>
    }
    return <div style={{
      width: 90,
      padding: 2,
      borderRadius: 100,
      background: '#FEE2E2', 
    }}>
      <Typography style={{
        fontFamily: 'Lato',  textAlign: 'center', color: '#DC2626',fontSize: 14,fontWeight: '700',
      }}>Expired</Typography>
    </div>
  }
  renderMySubscriptionTab = () => {
    return <div style={{ display: "flex" , flexDirection: 'column', marginTop: 10 }}>
    
        <LatoText style={{ fontWeight: '800', fontSize: 20, marginBottom: 10 }}>Billing History</LatoText>

      {this.renderTableBillingHistory()}
    </div>
  }
  renderTableRows = (transaction: IBillingHistory) => {
    if (!transaction) {
      return <></>
    }
    const options = { year: "numeric", month: "short", day: "numeric" } as const
    return <TableRow
    key={transaction.transaction}
      data-test-id="patient-item"
    >
      <StyledTableCell sx={{ width: "13vw" }} align="left">
      {moment(transaction.date).format("MMM DD, YYYY")}
      </StyledTableCell>
      <StyledTableCell sx={{ width: "9vw", textTransform: 'capitalize' }} align="left">{transaction.transaction}</StyledTableCell>
      <StyledTableCell sx={{ width: "145px" }} align="left">{`-$ ${(transaction.price / 100).toFixed(2)}`}</StyledTableCell>
      <StyledTableCell sx={{ width: "10vw" }} align="left">{`${transaction.type} Months`}</StyledTableCell>
      <StyledTableCell sx={{ width: "225px", textTransform: 'capitalize' }} align="left">
        {transaction.payment_type || ''}
      </StyledTableCell>
      <StyledTableCell sx={{ width: "225px" }} align="left">{this.renderStatus(transaction.status)}</StyledTableCell>
    </TableRow>
  }
  renderTableHeader = (item: { id: string; text: string; width: string }) => {

    const renderHeaderContent = () => {
      return <Typography style={{ fontFamily: 'Lato',  fontWeight: '700',fontSize: 16, color: "#343C32" }}>
        {item.text}
      </Typography>
    }
      return <StyledTableCell sx={{ width: item.width }} key={item.id} align="left">
        <div style={{ display: 'flex', flexDirection: 'row',  justifyContent: 'space-between',alignItems: 'center', }}>
          {renderHeaderContent()}
        </div>
      </StyledTableCell>
  }
 
  renderBreadcrumbs = () => {
    const containerStyle = {display: 'flex', columnGap: 10, alignItems: 'center', paddingLeft: 10, paddingTop: 4, paddingBottom: 4, borderBottom: '1px solid #F2F4F1'}
    return <div style={{...containerStyle, flexDirection: 'row'}}>
      <IconButton 
      data-test-id="button-back"
      onClick={this.goBackSetting}>
        <ArrowBackIcon style={{color: "#343C32"}}/>
        </IconButton>
      <ButtonBread  data-test-id="button-home" onClick={this.goBackDashboard}>
      <LatoText 
      
      style={{color: "#475569", textTransform: "capitalize"}}>Home</LatoText>
      </ButtonBread>
          <KeyboardArrowRightIcon style={{color: "#C3CBC0"}}/>
      <ButtonBread data-test-id="button-setting" onClick={this.goBackSetting}>
      <LatoText 
       
      style={{color: "#475569", textTransform: "capitalize"}}>Settings</LatoText>
      </ButtonBread>
      <KeyboardArrowRightIcon style={{color: "#C3CBC0"}}/>
      <LatoText style={{fontWeight: '700'}}>View All Billing History</LatoText>
    </div> 
  }
  renderHeader = () => {
    return   <HospiceHeader
    navigation={this.props.navigation}
    data-test-id={"authorized-header"}
  />
  }

  render() {
    return (
      <Container>
        <LayoutContainer>
        {this.renderHeader()}

          {this.renderBreadcrumbs()}
        <ContentContainer>
          {this.renderMySubscriptionTab()}
        </ContentContainer>
        </LayoutContainer>
      </Container>
    );
  }
}
const ButtonBread = styled(MaterialButton)({
  padding: '4px 8px',
  borderRadius: '6px',
  '&:hover': {
    backgroundColor: '#F1F5F9',
  },
});

const LatoText = styled(MaterialTypography)({
  fontSize: 16,
  fontFamily: "Lato",
  color: "#0F172A",
})

const LayoutContainer = styled(Box)({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  padding: "20px 20px 14px 20px",
  "@media (max-width: 600px)": {
    padding: "0px 0px 0px 0px",
  },
});
const Container = styled(Box)({
  width: "100%", height: "100%", display: "flex", flex: 1,
  overflowY: 'scroll',
});
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: 14,
  fontFamily: "Lato",
  fontWeight: 500,
  color: "#586554",
 
  [`&.${tableCellClasses.body}`]: {
    border: `1px solid #7C8E7633`,
  },
  [`&.${tableCellClasses.head}`]: {
    border: `1px solid #7C8E7633`,
  },
}));

const ContentContainer = styled(Box)({
  display: 'flex', flex: 1, flexDirection: 'column', padding: 100, paddingTop: 20 ,
  "@media (max-width: 600px)": {
    padding: 16,
  },
});
const TableWrapper = styled(Box)({
  display: 'flex', 
  "@media (max-width: 600px)": {
    overflowX: 'scroll'
  },
});


// Customizable Area End
