import React from "react";

import { Input, Button, Typography } from '@builder/component-library';

// Customizable Area Start
import { Formik } from "formik";
import * as Yup from "yup";
import HospiceHeader from "../../../components/src/HospiceHeader";
import Loader from "../../../components/src/Loader.web";
import CustomModal from "../../../components/src/CustomModal";
import {
  Box,
  Input as MaterialInput,
  Typography as MaterialTypography,
  TextareaAutosize,
  MenuItem,
  styled,
  IconButton,
  Button as MaterialButton
} from "@mui/material";
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
const config = require("../../../framework/src/config");
import Toast from "../../../components/src/Toast";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {hospiceLogoImage,headerTable}  from './assets'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import moment from 'moment'
// Customizable Area End

import Settings2Controller, {
  IPlan,
  ISubscription,
  Props,
  configJSON,
  IBillingHistory
} from "./Settings2Controller";

export default class Settings2 extends Settings2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  isImageFile = (file: File) => {
    return file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "image/png"
  }
  validationSchemaInformation = Yup.object().shape({
    hospiceName: Yup.string().trim().required(configJSON.errorMessageHospiceName),
    contactNumber: Yup.string().trim().matches(configJSON.regexContactNumber, configJSON.errorMessageContactNumber)
      .required(configJSON.errorMessageContactNumber),
    website: Yup.string().trim()
      .required(configJSON.errorMessageWebsite).url(configJSON.errorMessageWebsite),
    license: Yup.string().trim(),
    address: Yup.string().trim().required(configJSON.errorMessageAddress),
    city: Yup.string().trim().required(configJSON.errorMessageCity),
    selectedState: Yup.string().required(configJSON.errorMessageSelectedState),
    postalCode: Yup.string().trim().required(configJSON.errorMessagePostalCode),
    hospiceLogoError: Yup.mixed().test(
      "type",
      configJSON.errorMessageHospiceLogo,
      (file) => {
        return file
          ? this.isImageFile(file)
          : true;
      }
    ),
  });
  validationChangeEmailForm = Yup.object().shape({
    email: Yup.string().trim().required(configJSON.errorMessageEmail).email(configJSON.errorMessageEmail),
    confirmEmail: Yup.string().trim().required(configJSON.errorMessageEmail).email(configJSON.errorMessageEmail).oneOf([Yup.ref('email'), null], 'Email must match'),
  })
  validationChangePasswordForm = Yup.object().shape({
    currentPassword: Yup.string().trim().required( configJSON.errorMessagePasswordMatch)
    .matches(this.regexPass, configJSON.errorMessagePasswordMatch)
    .required(configJSON.errorMessagePasswordMatch),
    newPassword: Yup.string().trim().required( configJSON.errorMessagePasswordMatch)
    .matches(this.regexPass, configJSON.errorMessagePasswordMatch)
    .required(configJSON.errorMessagePasswordMatch),
    confirmNewPassword: Yup.string().trim().required( configJSON.errorMessagePasswordMatch)
    .matches(this.regexPass, configJSON.errorMessagePasswordMatch)
    .required(configJSON.errorMessagePasswordMatch).oneOf([Yup.ref('newPassword'), null], 'Password must match'),
  })
  renderHeader = () => {
    return   <HospiceHeader
    navigation={this.props.navigation}
    data-test-id={"authorized-header"}
  />
  }
  renderToast = () => {
    return (
      <Toast data-test-id="popup-message" textMessage={this.state.successMessage} isShow={this.state.isShowToast} width={401}/>
    )
  }
  renderImage = (imageRaw: File | null, hospiceLogoUrl: string | null) => {
    if(imageRaw){
      return <img src={URL.createObjectURL(imageRaw)} style={{ width: 90, height: 90, borderRadius: 50,objectFit: "cover"}}/>
    }
    if(hospiceLogoUrl){
      return <img src={`${config.baseURL}/${hospiceLogoUrl}`} style={{ width: 90, height: 90, borderRadius: 50, objectFit: "cover"}}/>
    }
    return <img src={hospiceLogoImage} style={{ width: 90, height: 90, borderRadius: 50, objectFit: "cover"}}/>
  }
  isExistFile = (target: HTMLElement & {
    files: FileList;
  }) => {
    if(target.files && target.files.length > 0){
      const file = target.files[0];
      return this.isImageFile(file)
    }
    return false
  }
  renderAccountSettingForm = () => {
    return (
      <Formik
        data-test-id="information-form"
        initialValues={this.state.initialInformationFormValues}
        onSubmit={(values, actions) => {
          this.handleSaveHospiceData(values)
        }}
        enableReinitialize
        validationSchema={this.validationSchemaInformation}
      >
        {({
          handleChange,
          handleSubmit,
          errors,
          setFieldTouched,
          values,
          setFieldValue,
          isValid,
          touched,
          setFieldError,
          resetForm,
          dirty
        }) => (
          <SecondFormStepContainer>
            <MainHeader style={{ marginTop: 20}}>{configJSON.titleYourHospiceLogo}</MainHeader>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap:2}}>
            <FormRow>
              <InputContainer>
                <InputImageContainer>
                  <div style={{ width: 100, height: 100, borderRadius: 50, display: 'flex', marginRight: 74, justifyContent: 'center', alignItems: 'center', borderWidth: 2, borderStyle: 'solid', background: "#F2F4F1", borderColor: "#A7B3A3" }}>
                    {this.renderImage(values.hospiceLogo, values.hospiceLogoUrl)}
                  </div>
                  <input
                    onChange={async (e: React.FormEvent<HTMLInputElement>) => {
                      const target = e.target as HTMLElement & {
                        files: FileList;
                      };
                      if (this.isExistFile(target)) {
                        const file = target.files[0];
                        await setFieldValue("hospiceLogo", file);
                        setFieldValue("hospiceLogoError", null)
                        setFieldError("hospiceLogoError", undefined)
                      } else {
                        const file = target.files[0];
                        await setFieldValue("hospiceLogoError", file);
                      }
                      setFieldTouched("hospiceLogo");
                    }}
                    data-test-id="hospice-logo-field"
                    id="hospiceLogo"
                    type="file"
                    hidden
                  />
                  <label
                    htmlFor="hospiceLogo"
                    className="logo-placeholder"
                    style={{ display: "flex", gap: "8px", alignItems: 'center' }}
                  >
                    <FileUploadOutlinedIcon style={{ width: 20, height: 20, color: "#586554" }} />
                    <p style={{ fontFamily: "Lato", fontWeight: "700", color: "#586554",cursor: 'pointer', fontSize: 16 }}>
                      {configJSON.btnUploadLogo}
                    </p>
                  </label>
                  <Button
                    data-test-id="clear-logo-button"
                    onPress={() => {
                      setFieldValue("hospiceLogo", null)
                      setFieldValue("hospiceLogoUrl", null)
                      setFieldValue("hospiceLogoError", null)
                      setFieldError("hospiceLogoError", undefined)
                    }}
                    style={{ backgroundColor: 'transparent', marginLeft: 12 }}
                    icon={<DeleteOutlineOutlinedIcon style={{ width: 20, height: 20, color: "#586554" }} />}
                    iconPosition="left"
                    textStyle={{ fontFamily: "Lato", fontWeight: "700", color: "#586554", fontSize: 16 }}
                    text={configJSON.btnRemoveLogo}
                  />
                </InputImageContainer>

                {this.isErrorField(errors.hospiceLogoError, touched.hospiceLogo) && (
                  <ValidationParagraph className="error">
                    {errors.hospiceLogoError}
                  </ValidationParagraph>
                )}
              </InputContainer>
            </FormRow>
            <div style={{width: "100%",
              height: "1px",
              marginTop: 20,
              marginBottom: 20,
                background: "#7C8E7633"}}/>
            <MainHeader style={{marginBottom: 16}}>{configJSON.formTitle}</MainHeader>
            <FormRow>
              <InputContainer>
                <InputLabel>
                  {configJSON.labelHospiceName}
                </InputLabel>
                <FormInput
                  onChange={handleChange("hospiceName")}
                  onBlur={() => setFieldTouched("hospiceName")}
                  className={this.getErrorClassName(errors.hospiceName, touched.hospiceName)}
                  id="hospiceName"
                  value={values.hospiceName}
                  data-test-id="hospice-name-field"
                  placeholder={configJSON.placeholderHospiceName}
                  disableUnderline
                />
                {this.isErrorField(errors.hospiceName, touched.hospiceName) && (
                  <ValidationParagraph data-test-id="hospice-name-error-message" className="error">
                    {errors.hospiceName}
                  </ValidationParagraph>
                )}
              </InputContainer>

              <InputContainer>
                <InputLabel>
                  {configJSON.labelContactNumber}
                </InputLabel>
                <FormInput
                  onChange={handleChange("contactNumber")}
                  onBlur={() => setFieldTouched("contactNumber")}
                  className={this.getErrorClassName(errors.contactNumber, touched.contactNumber)}
                  id="contactNumber"
                  value={values.contactNumber}
                  data-test-id="contact-number-field"
                  placeholder={configJSON.placeholderContactNumber}
                  disableUnderline
                />
                {this.isErrorField(errors.contactNumber, touched.contactNumber) && (
                  <ValidationParagraph className="error">
                    {errors.contactNumber}
                  </ValidationParagraph>
                )}
              </InputContainer>
            </FormRow>

            <FormRow>
              <InputContainer>
                <InputLabel>
                  {configJSON.labelWebsite}
                </InputLabel>
                <FormInput
                  onChange={handleChange("website")}
                  onBlur={() => setFieldTouched("website")}
                  className={this.getErrorClassName(errors.website, touched.website)}
                  id="website"
                  value={values.website}
                  data-test-id="website-field"
                  placeholder={configJSON.placeholderWebsite}
                  disableUnderline
                />
                {this.isErrorField(errors.website, touched.website) && (
                  <ValidationParagraph className="error">
                    {errors.website}
                  </ValidationParagraph>
                )}
              </InputContainer>

              <InputContainer>
                <InputLabel>
                  {configJSON.labelLicense}
                </InputLabel>
                <FormInput
                  onChange={handleChange("license")}
                  id="license"
                  value={values.license}
                  data-test-id="license-field"
                  placeholder={configJSON.placeholderLicense}
                  disableUnderline
                />
              </InputContainer>
            </FormRow>

            <FormRow>
              <InputContainer>
                <InputLabel>
                  {configJSON.labelAddress}
                </InputLabel>
                <FormTextArea
                  style={{ resize: "none" }}
                  minRows={3}
                  onChange={handleChange("address")}
                  onBlur={() => setFieldTouched("address")}
                  className={this.getErrorClassName(errors.address, touched.address)}
                  id="address"
                  value={values.address}
                  data-test-id="address-field"
                  placeholder={configJSON.placeholderAddress}
                />
                {this.isErrorField(errors.address, touched.address) && (
                  <ValidationParagraph className="error">
                    {errors.address}
                  </ValidationParagraph>
                )}
              </InputContainer>
            </FormRow>

            <FormRow>
              <InputContainer>
                <InputLabel>
                  {configJSON.labelCity}
                </InputLabel>
                <FormInput
                  onChange={handleChange("city")}
                  onBlur={() => setFieldTouched("city")}
                  className={this.getErrorClassName(errors.city, touched.city)}
                  id="city"
                  value={values.city}
                  data-test-id="city-field"
                  placeholder={configJSON.placeholderCity}
                  disableUnderline
                />
                {this.isErrorField(errors.city, touched.city) && (
                  <ValidationParagraph className="error">{errors.city}</ValidationParagraph>
                )}
              </InputContainer>

              <InputContainer>
                <InputLabel>
                  {configJSON.labelSelectedState}
                </InputLabel>
                <StateSelect
                  onChange={(event: SelectChangeEvent<any>) => {
                    setFieldValue("selectedState", event.target.value)
                  }}
                  onBlur={() => setFieldTouched("selectedState")}
                  className={this.getErrorClassName(errors.selectedState, touched.selectedState)}
                  defaultValue=""
                  id="state"
                  value={values.selectedState}
                  data-test-id="state-field"
                  disableUnderline
                  displayEmpty
                  IconComponent={KeyboardArrowDownRoundedIcon}
                >
                  <MenuItem disabled value="">
                    <Typography style={{fontFamily: "Lato", color: "#C3CBC0"}}>{configJSON.placeholderSelectedState}</Typography>
                  </MenuItem>
                  {configJSON.listOfStates.map((state: string) => (
                    <MenuItem value={state} key={state}>{state}</MenuItem>
                  ))}
                </StateSelect>
                {this.isErrorField(errors.selectedState, touched.selectedState) && (
                  <ValidationParagraph className="error">
                    {errors.selectedState}
                  </ValidationParagraph>
                )}
              </InputContainer>
            </FormRow>

            <FormRow>
              <InputContainer>
                <InputLabel>
                  {configJSON.labelPostalCode}
                </InputLabel>
                <FormInput
                  onChange={handleChange("postalCode")}
                  value={values.postalCode}
                  onBlur={() => setFieldTouched("postalCode")}
                  data-test-id="postal-code-field"
                  className={this.getErrorClassName(errors.postalCode, touched.postalCode)}
                  id="postalCode"
                  placeholder={configJSON.placeholderPostalCode}
                  disableUnderline
                />
                {this.isErrorField(errors.postalCode, touched.postalCode) && (
                  <ValidationParagraph className="error">
                    {errors.postalCode}
                  </ValidationParagraph>
                )}
              </InputContainer>
            </FormRow>
            </Box>
              {dirty && <div style={{display: 'flex', width: '100%', justifyContent: 'flex-end', marginTop: 10}}>
                {this.renderButtonCancel(resetForm)}
              <Button
                      text={configJSON.btnTxtSave}
                      style={{backgroundColor: true ? "#7C8E76" : "#D6DCD5", width: 120, marginLeft: 12}}
                      data-test-id={"button-submit-info"}
                      loading={this.state.loading}
                      onPress={() => {handleSubmit()}}
                      textStyle={{fontSize: 16, fontWeight: '700', color: true ? '#fff' : "#7C8E76", fontFamily: "Lato"}}
                    />
              </div>}
          </SecondFormStepContainer>
        )}
      </Formik>)
  }
  renderTab = () => {
    return <div className="mySubscriptionTab" style={{display: 'flex', flexDirection: 'row', borderBottom: "1px solid #7C8E7680", padding: 4}}>
     {["Account Settings", "Login & Security", "My Subscription"].map((item: string) => {
      const isActive = this.state.activeTab === item
      return  (
        <div
      key={item}
      data-test-id="change-tab-button"
      onClick={() => this.onChangeTab(item)}
     style={isActive ? webStyles.activeButton : webStyles.inactiveButton}>
      <p style={{color: isActive ? "#586554" : "#96A591", fontWeight: "700",fontFamily: "Lato"}}>{item}</p>
     </div>
      )

     }) }
    </div>
  }
  renderButtonCancel = (handleCancel: () => void) => {
    return       <Button
    data-test-id={"btn-register"}
    style={{backgroundColor: "#fff", borderWidth: 1, borderColor: "#D6DCD5", width: 120}}
    textStyle={{fontSize: 16, fontWeight: '700', color: '#7C8E76', fontFamily: "Lato"}}
    text={configJSON.btnTxtCancel}
    onPress={handleCancel}
  />
  }
  renderChangeEmailForm = () => {
    return   <Formik
    data-test-id="change-email-form"
    initialValues={this.initialChangeEmailForm}
    onSubmit={(value) => this.handleChangeEmail(value)}
    validationSchema={this.validationChangeEmailForm}
    innerRef={this.formikChangeEmailRef}
  >
    {({
      handleChange,
      handleSubmit,
      errors,
      setFieldTouched,
      values,
      setFieldValue,
      isValid,
      touched,
      resetForm,
      dirty
    }) => (
      <SecondFormStepContainer sx={{borderBottom: "1px solid #7C8E7633"}}>
<MainHeader style={{ marginTop: 20}}>{configJSON.titleChangeEmail}</MainHeader>
<FormRow>
              <InputContainer>
                <InputLabel>
                  {configJSON.labelEmail}
                </InputLabel>
                <FormInput
                  onChange={handleChange("email")}
                  value={values.email}
                  onBlur={() => setFieldTouched("email")}
                  className={this.getErrorClassName(errors.email, touched.email)}
                  id="email"
                  data-test-id="email-field"
                  placeholder={configJSON.placeHolderEmail}
                  disableUnderline
                />
                {this.isErrorField(errors.email, touched.email) && (
                  <ValidationParagraph data-test-id="email-error-message" className="error">
                    {errors.email}
                  </ValidationParagraph>
                )}
              </InputContainer>

              <InputContainer>
                <InputLabel>
                  {configJSON.labelConfirmEmail}
                </InputLabel>
                <FormInput
                  onChange={handleChange("confirmEmail")}
                  onBlur={() => setFieldTouched("confirmEmail")}
                  className={this.getErrorClassName(errors.confirmEmail, touched.confirmEmail)}
                  id="confirmEmail"
                  value={values.confirmEmail}
                  data-test-id="confirm-email-field"
                  placeholder={configJSON.placeHolderConfirmEmail}
                  disableUnderline
                />
                {this.isErrorField(errors.confirmEmail, touched.confirmEmail) && (
                  <ValidationParagraph data-test-id="confirm-email-error-message" className="error">
                    {errors.confirmEmail}
                  </ValidationParagraph>
                )}
              </InputContainer>
            </FormRow>
            {dirty && <div style={{display: 'flex', width: '100%', justifyContent: 'flex-end', marginTop: 10}}>
              {this.renderButtonCancel(resetForm)}
              <Button
                      data-test-id={"button-change-email"}
                      style={{backgroundColor: isValid ? "#7C8E76" : "#D6DCD5", width: 154, marginLeft: 12}}
                      onPress={() => {
                        if(isValid){
                          handleSubmit()
                        }
                      }}
                      loading={this.state.changingEmail}
                      textStyle={{fontSize: 16, fontWeight: '700', color:isValid ? '#fff' : "#7C8E76", fontFamily: "Lato"}}
                      text={configJSON.textChangeEmailButton}
                    />
              </div>}
          </SecondFormStepContainer>
        )}
      </Formik>
  }
  renderChangePasswordForm = () => {
    return <Formik
      data-test-id="change-password-form"
      initialValues={this.initialChangePasswordForm}
      onSubmit={(value) => this.handleChangePassword(value)}
      validationSchema={this.validationChangePasswordForm}
      innerRef={this.formikChangePasswordRef}
    >
      {({
        handleChange,
        handleSubmit,
        errors,
        setFieldTouched,
        values,
        setFieldValue,
        isValid,
        touched,
        resetForm,
        dirty
      }) => (
        <SecondFormStepContainer>
          <MainHeader style={{ marginTop: 20 }}>{configJSON.textChangePasswordButton}</MainHeader>
          <FormRow>
            <InputContainer>
              <InputLabel>
                {configJSON.labelOldPassword}
              </InputLabel>
              <FormInput
                onChange={handleChange("currentPassword")}
                onBlur={() => setFieldTouched("currentPassword")}
                className={this.getErrorClassName(errors.currentPassword, touched.currentPassword)}
                id="currentPassword"
                data-test-id="current-password-field"
                placeholder={configJSON.placeHolderOldPassword}
                disableUnderline
                value={values.currentPassword}
                type={values.isShowCurrentPassword ? "text" : "password"}
                endAdornment={
                  <PasswordVisibilityIconButton onClick={() => setFieldValue("isShowCurrentPassword", !values.isShowCurrentPassword)}>
                    {values.isShowCurrentPassword ? (
                      <VisibilityOutlinedIcon />
                    ) : (
                      <VisibilityOffOutlinedIcon />
                    )}
                  </PasswordVisibilityIconButton>
                }
              />
              {this.isErrorField(errors.currentPassword, touched.currentPassword) && (
                <ValidationParagraph data-test-id="current-password-error-message" className="error">
                  {errors.currentPassword}
                </ValidationParagraph>
              )}
            </InputContainer>

            <InputContainer>
              <InputLabel>
                {configJSON.labelNewPassword}
              </InputLabel>
              <FormInput
                onChange={handleChange("newPassword")}
                onBlur={() => setFieldTouched("newPassword")}
                className={this.getErrorClassName(errors.newPassword, touched.newPassword)}
                id="newPassword"
                data-test-id="new-password-field"
                placeholder={configJSON.placeHolderNewPassword}
                disableUnderline
                value={values.newPassword}
                type={values.isShowNewPassword ? "text" : "password"}
                endAdornment={
                  <PasswordVisibilityIconButton onClick={() => setFieldValue("isShowNewPassword", !values.isShowNewPassword)}>
                    {values.isShowNewPassword ? (
                      <VisibilityOutlinedIcon />
                    ) : (
                      <VisibilityOffOutlinedIcon />
                    )}
                  </PasswordVisibilityIconButton>
                }
              />
              {this.isErrorField(errors.newPassword, touched.newPassword) && (
                <ValidationParagraph data-test-id="new-password-error-message" className="error">
                  {errors.newPassword}
                </ValidationParagraph>
              )}
            </InputContainer>
          </FormRow>
          <FormRow>
            <InputContainer>
              <InputLabel>
                {configJSON.labelConfirmPassword}
              </InputLabel>
              <FormInput
                onChange={handleChange("confirmNewPassword")}
                onBlur={() => setFieldTouched("confirmNewPassword")}
                className={this.getErrorClassName(errors.confirmNewPassword, touched.confirmNewPassword)}
                id="confirmNewPassword"
                data-test-id="confirm-password-field"
                placeholder={configJSON.placeHolderConfirmPassword}
                disableUnderline
                value={values.confirmNewPassword}
                type={values.isShowConfirmNewPassword ? "text" : "password"}
                endAdornment={
                  <PasswordVisibilityIconButton onClick={() => setFieldValue("isShowConfirmNewPassword", !values.isShowConfirmNewPassword)}>
                    {values.isShowConfirmNewPassword ? (
                      <VisibilityOutlinedIcon />
                    ) : (
                      <VisibilityOffOutlinedIcon />
                    )}
                  </PasswordVisibilityIconButton>
                }
              />
              {this.isErrorField(errors.confirmNewPassword, touched.confirmNewPassword) && (
                <ValidationParagraph data-test-id="confirm-password-error-message" className="error">
                  {errors.confirmNewPassword}
                </ValidationParagraph>
              )}
            </InputContainer>

            <InputContainer>
            </InputContainer>
          </FormRow>
         {dirty && <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', marginTop: 10, paddingBottom: 30 }}>
            {this.renderButtonCancel(resetForm)}
            <Button
              data-test-id={"button-change-password"}
              onPress={() => {
                if(isValid){
                  handleSubmit()
                }
              }}
              loading={this.state.loading}
              textStyle={{ fontSize: 16, fontWeight: '700', color:isValid ? '#fff' : "#7C8E76", fontFamily: "Lato" }}
              style={{ backgroundColor:  isValid ? "#7C8E76" : "#D6DCD5" , width: 154, marginLeft: 12 }}
              text={configJSON.textChangePasswordButton}
            />
          </div>}
        </SecondFormStepContainer>
      )}
    </Formik>
  }
  renderLoginSecurityTab = () => {
    return <div style={{display: this.state.activeTab === "Login & Security" ?"flex" :"none"   , flexDirection: 'column', rowGap: 40,}}>
      {this.renderChangeEmailForm()}
      {this.renderChangePasswordForm()}
    </div>
  }
  renderAccountSettingTab = () => {
    return <div style={{ display: this.state.activeTab === "Account Settings" ? "flex" : "none", flexDirection: 'column', }}>
      {this.renderAccountSettingForm()}
      <div style={{
        width: "100%",
        height: "1px",
        marginBottom: 20,
        background: "#7C8E7633"
      }} />
      <div style={{ display: 'flex', width: "100%", alignItems: 'center', flexDirection: 'row', marginTop: 20, justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <MainHeader>{configJSON.titleDeleteAccount}</MainHeader>
          <Typography style={{ fontSize: 14, marginTop: 14, fontWeight: "500", fontFamily: "Lato", color: "#586554", width: "70%" }}>{configJSON.deleteAccountDescription}</Typography>
        </div>
        <Button
          data-test-id="clear-logo-button"
          style={{ backgroundColor: 'transparent', marginLeft: 12 }}
          icon={<DeleteOutlineOutlinedIcon style={{ width: 20, height: 20, color: "#DC2626" }} />}
          iconPosition="left"
          onPress={this.handleNavigateDeleteAccount}
          textStyle={{ fontFamily: "Lato", fontWeight: "700", color: "#DC2626", fontSize: 16 }}
          text={configJSON.btnDeleteAccount} />
      </div>
    </div>
  }
  renderTableBillingHistory = () => {
    const width = document.querySelector(".mySubscriptionTab")?.getBoundingClientRect().width || 0;

    return  <TableContainer sx={{width, minWidth: 0}}>
    <Table data-test-id="table-patient-data" aria-label="simple table" sx={{whiteSpace: "nowrap"}}>
      <TableHead>
        <TableRow>
          {headerTable.map(item => {
            return this.renderTableHeader(item)
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {this.renderTableRows()}
      </TableBody>
    </Table>
    </TableContainer>
  }
  renderTableHeader = (item: { id: string; text: string; width: string }) => {

    const renderHeaderContent = () => {
      return <Typography style={{ fontFamily: 'Lato', fontSize: 16, fontWeight: '700', color: "#343C32" }}>
        {item.text}
      </Typography>
    }
      return <StyledTableCell sx={{ width: item.width }} key={item.id} align="left">
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          {renderHeaderContent()}
        </div>
      </StyledTableCell>
  }
  renderStatus = (status: boolean) => {
    if (status === true) {
      return <div style={{
        padding: 2,
        width: 90,
        background: '#D1FAE5', borderRadius: 100
      }}>
        <Typography style={{
          fontFamily: 'Lato', textAlign: 'center', fontWeight: '700', color: '#059669', fontSize: 14
        }}>Paid</Typography>
      </div>
    }
    return <div style={{
      padding: 2,
      width: 90,
      background: '#FEE2E2', borderRadius: 100
    }}>
      <Typography style={{
        fontFamily: 'Lato', fontWeight: '700', textAlign: 'center', color: '#DC2626',fontSize: 14
      }}>Expired</Typography>
    </div>
  }
  renderTableRows = () => {
    if (this.state.listBillingHistory.length <= 0) {
      return <></>
    }
    const options = { year: "numeric", month: "short", day: "numeric" } as const
    const listTransaction = this.state.listBillingHistory.slice(0, 4)
    return listTransaction.map((transaction: IBillingHistory) =>  <TableRow
      data-test-id="patient-item"
      key="data-row"
    >
      <StyledTableCell sx={{ width: "13vw" }} align="left">
        {moment(transaction.date).format("MMM DD, YYYY")}
      </StyledTableCell>
      <StyledTableCell sx={{ width: "9vw", textTransform: 'capitalize' }} align="left">
        {transaction.transaction}
      </StyledTableCell>
      <StyledTableCell sx={{ width: "145px" }} align="left">{`-$ ${(transaction.price / 100).toFixed(2)}`}</StyledTableCell>
      <StyledTableCell sx={{ width: "10vw" }} align="left">{`${transaction.type} Months`}</StyledTableCell>
      <StyledTableCell sx={{ width: "225px", textTransform: 'capitalize' }} align="left">
        {transaction.payment_type || ''}
      </StyledTableCell>
      <StyledTableCell sx={{ width: "225px" }} align="left">{this.renderStatus(transaction.status)}</StyledTableCell>
    </TableRow>)
  }
  onClickUpgradeDowngradePlan = (isActive: boolean, interval: string) => {
    if(isActive){
      this.handleOpenModalCancelPlan()
      return
    }
    if(interval === "6"){
      this.handleOpenModal()
      return
    }
    this.handleOpenUpgradeModal()
  }
  getButtonText = (isActive: boolean, interval: string) => {
    const isCanceled = this.state.currentSubscription.auto_renewal === "no"
    if(isActive){
      if(isCanceled){
        return "Continue Plan"
      }
      return "Cancel Plan"
    }
    if(interval === "6"){
      return "Downgrade Plan"
    }
    return "Upgrade Plan"
  }
  renderButtonPlanCard = (isActive: boolean, product: IPlan) => {
    const isCanceled = this.state.currentSubscription.auto_renewal === "no"
    const currentChangingPlan = this.state.currentUpgradeOrDowngrade
    if (currentChangingPlan !== null && currentChangingPlan.plan === product.stripe_price_id) {
      const isCancelUpgrade = product.interval === "12"
      return <Button
        data-test-id={"button-plan"}
        onPress={() => {
          if (isCancelUpgrade) {
            this.handleOpenModalCancel()
          }else {
            this.handleOpenModalCancelDowngradePlan()
          }
        }}
        style={{ backgroundColor: "#7C8E76", width: 150, marginTop: 20 }}
        textStyle={{ fontSize: 16, fontWeight: '700', color: '#fff', fontFamily: "Lato" }}
        text={isCancelUpgrade ? "Cancel Upgrade" : "Cancel Downgrade"}
      />
    }
    return <Button
      data-test-id={"button-plan"}
      onPress={() => {
        if(isActive && isCanceled){
          this.handleOpenModalContinuePlan()
        }else{
          this.setState({ selectedPlanId: product.stripe_price_id })
          this.onClickUpgradeDowngradePlan(isActive, product.interval)

        }
      }}
      style={{ backgroundColor: "#7C8E76", width: 150, marginTop: 20 }}
      textStyle={{ fontSize: 16, fontWeight: '700', color: '#fff', fontFamily: "Lato" }}
      text={this.getButtonText(isActive, product.interval)}
    />
  }
  renderPlanCard = (product: IPlan, isActive: boolean, subscription?: ISubscription) => {
    const currentDate = moment();
    const startDate = subscription ? subscription?.started_at : currentDate
    const renewalDate = moment(startDate).add(product.interval, 'months')
    const renewalIn = renewalDate ? renewalDate.diff(currentDate, 'months') : 0
    const isCanceled = this.state.currentSubscription.auto_renewal === "no"
    return <CardPlan key={product.id}>
   {isActive && <div style={{display: 'flex', width: 55, height: 23, borderRadius: 12, background: '#D5F7CA', position: 'absolute', top: -12, right: -12, justifyContent: 'center', alignItems: 'center'}}>
        <LatoText style={{fontWeight: '700', fontSize: 12}}>Active</LatoText>
        </div>}
      <CardPlanHead>
        <DurationPlanText>{`${product.interval} Month Plan`}</DurationPlanText>
        <Box sx={{ display: "flex", flexDirection: 'row', alignItems: 'flex-end' }}>
          <PriceText>{`$ ${product.price}`}</PriceText>
          <DurationText>{`/${product.interval} months`}</DurationText>
        </Box>

      </CardPlanHead>
      <DescriptionText>
        {!isActive ? `This subscription plan will auto-renew every ${product.interval} months.` : 
          `Your plan will automatically renew in ${renewalIn} months`}
      </DescriptionText>
      <Box sx={{ display: "flex", flexDirection: 'row', justifyContent: 'space-between', alignItems: "flex-end" }}>
       {this.renderButtonPlanCard(isActive, product)}
        {isActive && <Box sx={{ display: "flex", flexDirection: "column" }}>
          <LatoText style={{ fontSize: 12, fontWeight: "400", color: "#A7B3A3" }}>{isCanceled ? "Ends on" :"Renews on"}</LatoText>
          <LatoText style={{ fontSize: 16, fontWeight: "700", color: "#343C32" }}>
            {moment(renewalDate).format("MMM DD, YYYY")}
            </LatoText>
        </Box>}
      </Box>

    </CardPlan>
  }

  renderMySubscriptionTab = () => {
    return <div style={{ display: this.state.activeTab === "My Subscription" ? "flex" : "none", flexDirection: 'column', marginTop: 10 }} >
      <LatoText style={{ fontWeight: '800', fontSize: 20 }}>Plan</LatoText>
      <ListPlan>
        {this.state.listProduct.map(product => this.state.currentSubscription?.plan.id === product.stripe_price_id ?
          this.renderPlanCard(product, true, this.state.currentSubscription) 
              : this.renderPlanCard(product, false))}
      </ListPlan>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 32, marginBottom: 10 }}>
        <LatoText style={{ fontWeight: '800', fontSize: 20 }}>Billing History</LatoText>
        <LatoText onClick={this.goToViewBilling} style={{ fontWeight: '700', fontSize: 14, color: "#96A591", cursor: 'pointer' }}>View All Billing History</LatoText>

      </div>
      {this.renderTableBillingHistory()}
    </div>
  }
  renderCancelCurrentPlanModal = () => {
    const now = moment();
    const compareTime = moment(this.state.currentSubscription.started_at)
    const isLessThanOneMonth = now.diff(compareTime, 'months', true) < 1;
    return <CustomModal
    data-test-id="modal-cancel"
      open={this.state.isShowModalCancelPlan}
      handleCloseModal={this.handleCloseModalCancelPlan}
      headerTitle="Cancel Plan"
      renderContent={() => {
        return <>
          <LatoText style={{ color: "#343C32", fontWeight: "400" }}>
           {isLessThanOneMonth ? "Are you sure you want to cancel your plan?" : "Are you sure you want to cancel the auto-renewal of your current plan?"}
          </LatoText>
          {isLessThanOneMonth ? <LatoText style={{ color: "#343C32", fontWeight: "400" }}>You and all Caregivers associated with patients on your account will no longer have access to Partner for Care. </LatoText> : <LatoText style={{ color: "#343C32", fontWeight: "400" }}>
          You and all Caregivers associated with patients on your account will no longer have access to Partner for Care after <b>[end date of current plan].</b>
          </LatoText>}
        </>
      }}
      textButtonLeft="End Plan"
      textButtonRight="Keep Plan"
      handleClickButtonLeft={this.handleCancelPlanButtonClick}
      handleClickButtonRight={this.handleCloseModalCancelPlan}
      height={600}
      loading={false}
    />
  }
  renderCancelDowngradePlanModal = () => {
    return <CustomModal
    data-test-id="modal-cancel-downgrade"
      open={this.state.isOpenCancelDowngradeModal}
      handleCloseModal={this.handleOpenModalCancelDowngradePlan}
      headerTitle="Cancel Downgrade"
      renderContent={() => {
        return <>
          <LatoText style={{ color: "#343C32", fontWeight: "400" }}>Please confirm you would like to cancel downgrading your plan to the 6 Month Plan. You will keep your current plan.
          </LatoText>
        </>
      }}
      textButtonLeft="Yes, downgrade"
      textButtonRight="Don’t downgrade"
      handleClickButtonLeft={this.handleCloseModalCancelDowngradePlan}
      handleClickButtonRight={this.handleCancelUpgradeDowngrade}
      height={600}
      loading={false}
    />
  }
  renderUpgradeSubscription = () => {
    return <CustomModal
     data-test-id="modal-upgrade"
      open={this.state.isOpenUpgradeModal}
      handleCloseModal={this.handleCloseUpgradeModal}
      headerTitle={'Upgrade Subscription'}
      renderContent={() => {
          return <LatoText style={{ color: "#343C32", marginTop: 4 }}>Excellent choice! Please confirm we can <span style={{ fontWeight: "700" }}>upgrade</span> your plan to the 12 Month Plan.</LatoText>
      }}
      textButtonLeft="Cancel"
      textButtonRight="Confirm"
      handleClickButtonLeft={this.handleCloseUpgradeModal}
      handleClickButtonRight={() => this.handleChangePlanButtonClick('upgrade')}
      height={600}
      loading={false}
    />
  }
  renderDowngradeSubscription = () => {
    const initialTime = moment(this.state.currentSubscription.started_at)
    const newTime = initialTime.add(6, 'months');
    const formattedTime = newTime.format('MMM DD, YYYY');
    return <CustomModal
    data-test-id="modal-downgrade"
      open={this.state.isOpenModal}
      handleCloseModal={this.handleCloseModal}
      headerTitle={'Downgrade Subscription'}
      renderContent={() => {
        return <>
          <LatoText style={{ color: "#343C32", marginTop: 4 }}>Are you sure you want to <span style={{ fontWeight: "700" }}>downgrade</span> your plan to the 6 Month Plan?</LatoText>
          <LatoText style={{ color: "#343C32", marginTop: 4 }}>Your new plan starts when your current one expires.</LatoText>
          <LatoText style={{ color: "#343C32", marginTop: 4 }}>
            <span style={{ fontWeight: "700" }}>New start date:</span> {formattedTime}</LatoText>
        </>
      }}
      textButtonLeft="Cancel"
      textButtonRight="Confirm"
      handleClickButtonLeft={this.handleCloseModal}
      handleClickButtonRight={() => this.handleChangePlanButtonClick('downgrade')}
      height={600}
      loading={false}
    />
  }
  renderCancelUpgradeModal = () => {
    return <CustomModal
      open={this.state.isShowModalCancel}
      handleCloseModal={this.handleCloseModalCancel}
      headerTitle="Cancel Upgrade"
      data-test-id="cancel-upgrade-modal"
      renderContent={() => {
        return <>
          <LatoText style={{ color: "#343C32", fontWeight: "400" }}>Are you sure you want to cancel upgrading your plan to the 12 Month Plan?</LatoText>
          <LatoText style={{ color: "#343C32", fontWeight: "400" }}>You will be reverted to your previous plan.</LatoText>
        </>
      }}
      textButtonLeft="No, don’t upgrade"
      textButtonRight="Yes, upgrade"
      handleClickButtonRight={this.handleCloseModalCancel}
      handleClickButtonLeft={this.handleCancelUpgradeDowngrade}
      height={600}
      loading={false}
    />
  }
  renderContinuePlanModal = () => {
    const currentPlan = this.state.currentSubscription.plan.interval_count.toString()
    return <CustomModal
      open={this.state.isShowContinuePlanModal}
      handleCloseModal={this.handleCloseModalContinuePlan}
      headerTitle="Continue Plan"
      data-test-id="continue-plan-modal"
      renderContent={() => {
        return <>
          <LatoText style={{ color: "#343C32", fontWeight: "400" }}>Please confirm you want to continue your {currentPlan} Month Plan? We will resume your auto-renewals.</LatoText>
        </>
      }}
      textButtonLeft="No, let my plan expire"
      textButtonRight="Yes, resume my plan"
      handleClickButtonRight={this.handleContinuePlan}
      handleClickButtonLeft={this.handleCloseModalContinuePlan}
      height={600}
      loading={false}
    />
  }
  renderBreadcrumbs = () => {
    const containerStyle = {display: 'flex', columnGap: 10, alignItems: 'center', paddingLeft: 10, paddingTop: 4, paddingBottom: 4, borderBottom: '1px solid #F2F4F1'}
    return <div style={{...containerStyle, flexDirection: 'row'}}>
      <IconButton 
      data-test-id="button-back"
      onClick={this.goBackDashboard}>
        <ArrowBackIcon style={{color: "#343C32"}}/>
        </IconButton>
      <ButtonBread data-test-id="button-home" onClick={this.goBackDashboard}>
      <LatoText 
      style={{color: "#475569", textTransform: "capitalize"}}>Home</LatoText>
      </ButtonBread>
      <KeyboardArrowRightIcon style={{color: "#C3CBC0"}}/>
      <LatoText style={{fontWeight: '700'}}>Settings</LatoText>
    </div> 
  }
  renderLoader = () => {
    return (
      <Loader loading={this.state.fetching}/>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Container>
        <LayoutContainer>
        {this.renderLoader()}
        {this.renderHeader()}
        {this.renderToast()}
          {this.renderBreadcrumbs()}
          {this.renderUpgradeSubscription()}
          {this.renderDowngradeSubscription()}
          {this.renderCancelUpgradeModal()}
          {this.renderCancelCurrentPlanModal()}
          {this.renderContinuePlanModal()}
          {this.renderCancelDowngradePlanModal()}
        <ContentContainer>
          {this.renderTab()}
          {this.renderAccountSettingTab()}
          {this.renderLoginSecurityTab()}
          {this.renderMySubscriptionTab()}
        </ContentContainer>
        </LayoutContainer>
      </Container>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyles = {
  activeButton: {
    background: "#F2F4F1",
    borderBottom: "1px solid #7C8E7680",
    height: 48,
    borderRadius: '2px',
    paddingTop: 6,
    paddingRight: 12,
    paddingBottom: 6,
    paddingLeft: 12
  },
  inactiveButton: {
    height: 48,
    background: "transparent",
    cursor: "pointer",
    paddingTop: 6,
    paddingRight: 12,
    paddingBottom: 6,
    paddingLeft: 12
  },
}
const ButtonBread = styled(MaterialButton)({
  padding: '4px 8px',
  borderRadius: '6px',
  '&:hover': {
    backgroundColor: '#F1F5F9',
  },
});
const SecondFormStepContainer = styled(Box)({
  boxSizing: 'border-box',
  height: "100%",
  // width: "600px",
  paddingLeft: "16px",
  paddingRight: "16px",
  display: "flex",
  paddingBottom: "30px",
  flexDirection: "column",
  justifyContent: "center",
  gap: "20px",
  "& button.active": {
    backgroundColor: "#7C8E76",
    color: "#FFFFFF",
  },
  "@media (max-width: 414px)": {
    width: "100%"
  },
});
const LatoText = styled(MaterialTypography)({
  fontSize: 16,
  fontFamily: "Lato",
  color: "#0F172A",
})
const StateSelect = styled(Select)({
  width: "100%",
  height: "44px",
  borderRadius: "8px",
  "& .MuiSelect-root": {
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "100%",
    paddingLeft: "8px",
    borderRadius: "8px",
    borderWidth: "1px",
    borderColor: "#C3CBC0",
    borderStyle: "solid",
  },
  "& .MuiSelect-select": {
    color: "#586554", 
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: 500
  }
});
const FormRow = styled(Box)({
  display: "flex",
  gap: "16px",
  "@media (max-width: 414px)": {
    flexDirection: "column",
  },
});
const InputImageContainer = styled(Box)({
  display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: 40 ,
  "@media (max-width: 414px)": {
    flexDirection: "column",
  },
});

const InputGroup = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
});
const MainHeader = styled(MaterialTypography)({
  fontFamily: "Lato",
  fontWeight: 700,
  fontSize: 24,
  letterSpacing: 0,
  color: "#343c32ff",
});
const InputContainer = styled(Box)({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  gap: "4px",
  "& .error": {
    color: "#DC2626",
    borderColor: "#F87171",
  },
  "& ::placeholder": {
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: 400,
    color: "#C3CBC0",
    textTransform: "none",
  },
  "& .logo-placeholder": {
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: 400,
    color: "#C3CBC0",
    textTransform: "none",
  },
});

const InputLabel = styled(MaterialTypography)({
  fontFamily: "Lato",
  fontWeight: 700,
  fontSize: 14,
  letterSpacing: 0,
  color: "#343c32ff",
});
const FormTextArea = styled(TextareaAutosize)({
  width: "100%",
  paddingLeft: "8px",
  borderRadius: "8px",
  borderWidth: "1px",
  borderColor: "#C3CBC0",
  borderStyle: "solid",
  fontFamily: "Lato",
  fontSize: 16,
  fontWeight: "500",
  color: "#586554",
});
const FormInput = styled(MaterialInput)({
  width: "100%",
  height: "44px",
  fontSize: 16,
  fontWeight: "500",
  color: "#586554",
  paddingLeft: "8px",
  paddingRight: "8px",
  borderRadius: "8px",
  borderWidth: "1px",
  borderColor: "#C3CBC0",
  borderStyle: "solid",
  fontFamily: "Lato",
  '& .MuiInputBase-input::placeholder': {
    color: '#94A3B8', // Default placeholder color
  },
  '&.error .MuiInputBase-input::placeholder': {
    color: '#94A3B8', // Placeholder color when error class is present
  },
});

const ValidationParagraph = styled(MaterialTypography)({
  fontFamily: "Lato",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "18px",
  textAlign: "left",
  width: 0,
  minWidth: "100%",
  color: "#7C8E76"
});
const PasswordVisibilityIconButton = styled(IconButton)({
  width: "21.65px",
  height: "18.95px",
  "& svg": {
    fill: "#C3CBC0",
  },
});
const LayoutContainer = styled(Box)({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  padding: "20px 20px 14px 20px",
  "@media (max-width: 600px)": {
    padding: "0px 0px 0px 0px",
  },
});
const Container = styled(Box)({
  width: "100%", height: "100%", display: "flex", flex: 1,
  overflowY: 'scroll',
});
const CardPlan = styled(Box)({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  border: "1px solid #7C8E76",
  padding: 12,
  paddingLeft: 20,
  paddingRight: 20,
  borderRadius: "16px",
  height: "121px",
  position: 'relative'
});
const ListPlan = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  gap: "22px",
   marginTop: 12,
   borderBottom: "1px solid #7C8E7633",
   paddingBottom: 20,
   "@media (max-width: 600px)": {
    flexDirection: 'column',
  },
});
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 500,
  fontFamily: "Lato",
  color: "#586554",
  [`&.${tableCellClasses.head}`]: {
    border: `1px solid #7C8E7633`,
  },
  [`&.${tableCellClasses.body}`]: {
    border: `1px solid #7C8E7633`,
  },
}));
const CardPlanHead = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const ContentContainer = styled(Box)({
  display: 'flex', flex: 1, flexDirection: 'column', padding: 100, paddingTop: 20 ,
  "@media (max-width: 600px)": {
    padding: 16,
  },
});
const DurationPlanText = styled(MaterialTypography)({
  fontFamily: "Lato",
  fontWeight:"700", fontSize: 20, color: "#586554",
  "@media (max-width: 600px)": {
    fontSize: 18
  },
})
const PriceText = styled(MaterialTypography)({
  fontFamily: "Lato",
  fontWeight:"900", fontSize: 20, color: "#343C32",
  
  "@media (max-width: 600px)": {
    fontSize: 18,

  },
})
const DurationText = styled(MaterialTypography)({
  fontFamily: "Lato",
  fontWeight:"400", fontSize: 12, color: "#7C8E76",
  marginBottom: 3,
  "@media (max-width: 600px)": {
    fontSize: 12,
        marginBottom: 0,
  },
})
const DescriptionText = styled(MaterialTypography)({
  fontFamily: "Lato",
  fontSize: 14, fontWeight: "400", color: "#A7B3A3",
  "@media (max-width: 600px)": {
    fontSize: 10
  },
})
const TableWrapper = styled(Box)({
  display: 'flex', 
  "@media (max-width: 600px)": {
    overflowX: 'scroll'
  },
});


// Customizable Area End
