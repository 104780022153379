import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { styled } from '@mui/system';

import ContentBreadcrumbs from "./ContentBreadcrumb.web";
import OverviewSection from "./OverviewSection.web";
import PlainTextItem from "./PlanTextItem.web";
import { CustomSectionContainer, SectionListLayout } from "./CustomContainer.web";
import { ISectionData } from "./SymptomARD.web";
import ReactHtmlParser from "./ReactHtmlParser";

import { IContentData, ITermDetail } from "../../blocks/contentmanagement/src/ContentManagementController";

const LightSectionTitle = styled(Box)({
  fontSize: '26px', 
  fontWeight: '700',
  fontFamily: 'Lato',
  color: '#586554',
  '& p': {
    margin: 0
  }
});

const DiscussionTopics = ({ data, onNavigate }: { data: IContentData, onNavigate: (to: string) => void }) => {
  const { attributes } = data;
  const { title, terms } = attributes;

  const [sectionList, setSectionList] = useState<ISectionData[]>([]);

  useEffect(() => {
    if (terms) {
      terms.sort((a, b) => a.id - b.id); 
      const groupedData: { [key: string]: ITermDetail[] } = {};
      terms.forEach(term => {
        const key = term.term;
        if (!groupedData[key]) {
          groupedData[key] = [];
        }
        groupedData[key].push(term);
      });
      const sections = Object.entries(groupedData).map(group => {
        if (group[1].length > 1) {
          return {
            name: group[0],
            definition: '',
            images: [],
            children: group[1].map(each => {
              return { definition: each.definition, images: each.images }
            })
          }
        }
        return {
          name: group[0],
          definition: group[1][0].definition,
          images: group[1][0].images,
        }
      })
      setSectionList(sections)
    }
  }, [terms]);

  const renderSectionItem = (htmlString: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const listWithSummary = doc.querySelector('ul + p');
    const listItems = Array.from(doc.querySelectorAll('li')).map(li => li.innerHTML);
    if (listWithSummary) {
      const pTag = doc.querySelector('p');  
      return <>
        {listItems.map((item, index) => (<PlainTextItem key={index} style={{ padding: '10px' }}>{ReactHtmlParser(item)}</PlainTextItem>))}
        <LightSectionTitle mt={2.5}>{ReactHtmlParser(pTag?.innerHTML || '')}</LightSectionTitle>
      </>
    }
    return listItems.map((item, index) => (<PlainTextItem key={index} style={{ padding: '10px' }}>{ReactHtmlParser(item)}</PlainTextItem>))
  }

  return (
    <Box>
      <ContentBreadcrumbs stacks='Dashboard/The first week in Hospice/Topics to discuss with Hospice Team' onNavigate={onNavigate} />
      <SectionListLayout styles={{ gap: '10px' }}>
        <OverviewSection 
          images={title ? title?.images.map(image => image.url) : []} 
          name={title && ReactHtmlParser(title?.title)} 
          shortDesc={title && title?.desc && title?.desc !== '' && ReactHtmlParser(title?.desc)}
        />
        {sectionList
          .map((section, index) => 
            <CustomSectionContainer
              key={index}
              withHeading
              heading={ReactHtmlParser(section.name)}
              headingStyle={{ px: 1.25 }}
              contentStyle={{ padding: "0 10px", gap: 0 }}
              containerStyle={index === sectionList.length - 1 ? { backgroundColor: '#FEF2F2', border: '2px dashed #F87171', padding:'20px 0' } : undefined}
            >
            {renderSectionItem(section.definition)}
          </CustomSectionContainer>
        )}
      </SectionListLayout>
    </Box>
  );
}

export default DiscussionTopics;
