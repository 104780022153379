import React, { CSSProperties, ReactNode } from 'react';
import { Typography, Box } from '@mui/material';
import { styled, SxProps } from '@mui/system';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const MainContainer = styled(Box)({
  background: '#F2F4F1',
  borderRadius: '8px',
  color: '#343C32'
});

const HeadingContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  maxWidth: '1024px',
  margin: '0 auto',
});

const TreatmentContainer = styled(Box)({
  display: 'inline-flex',
  alignItems: 'start',
});

const TreatmentList = styled(Box)({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  textAlign: 'left',
});

const TreatmentItemLayout = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
});

const Subtitle = styled(Typography)({
  fontFamily: 'Lato',
  fontWeight: '400',
  fontSize: '22px',
  color: '#586554',
});

const QuestionText = styled(Subtitle)({
  fontSize: '26px',
  fontWeight: '700',
});

const QuestionTextContainer = styled(Box)({
  fontFamily: 'Lato',
  fontSize: '26px',
  fontWeight: '700',
  color: '#586554',
  '& p': {
    margin: 0
  }
});

const DescImg = styled('img')({
  width: '120px',
  height: '120px',
  objectFit: 'contain'
});

type IMethodOverviewSectionProps = {
  title: ReactNode;
  subtitle: string;
  mainTitle: string | undefined;
  image: ReactNode;
  benefits: { definition: string }[];
};

type IOverviewAnnotationProps = {
  title: ReactNode | string;
  image?: string;
  content: ReactNode;
  sx?: SxProps;
  titleStyle?: SxProps;
  layoutStyle?: SxProps;
  contentStyle?: SxProps;
}

export const OverviewAnnotation = (props: IOverviewAnnotationProps) => (
  <Box sx={{ flex: 1, ...props.sx }}>
   <QuestionTextContainer sx={{ fontSize: { xs: 20, md: 26 }, mb: { xs: 2.5, md: 4 }, ...props.titleStyle }}>{props.title}</QuestionTextContainer>
   <TreatmentContainer sx={{ flexWrap: { xs: 'wrap', md: 'nowrap' }, gap: { xs: '16px', md: '35px' }, ...props.layoutStyle }}>
    {props.image && <DescImg sx={{ width: { xs: '80px', md: '120px' }, height: { xs: '80px', md: '120px' } }} src={props.image} alt='Desc Img' />}
    <TreatmentList sx={{ gap: { xs: '16px', md: '20px'}, ...props.contentStyle }}>{props.content}</TreatmentList>
   </TreatmentContainer>
  </Box>
);


const MethodOverviewSection = (props: IMethodOverviewSectionProps) => {
  const renderBenefitsWithIcons = (definition: string) => {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = definition;
    const listItems = tempElement.querySelectorAll('li');

    return (
      <ul style={{ padding: "0px" }}>
        {Array.from(listItems).map((item, index) => (
          <li key={index} style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <ChevronRightIcon />
            <span style={{ fontSize: "22px", color: "#343C32" }} dangerouslySetInnerHTML={{ __html: item.innerHTML }} />
          </li>
        ))}
      </ul>
    );
  };

  return (
    <MainContainer sx={{ padding: { xs: '20px 20px', md: '40px 130px' } }}>
      <HeadingContainer  sx={{ fontSize: "26px" }}>
        {props.title}
        <Subtitle dangerouslySetInnerHTML={{ __html: props?.subtitle }} mt={2} />
      </HeadingContainer>
      <QuestionText mt={5} mb={4} dangerouslySetInnerHTML={{ __html: props?.mainTitle || '' }} />
      <TreatmentContainer>
        {props.image}
        <TreatmentList>
          {props.benefits.map((benefit, index) => (
            <TreatmentItemLayout key={index}>
              <Typography variant="body1">{renderBenefitsWithIcons(benefit.definition)}</Typography>
            </TreatmentItemLayout>
          ))}
        </TreatmentList>
      </TreatmentContainer>
    </MainContainer>
  );
};

export default MethodOverviewSection;
