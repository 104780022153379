import React, { useEffect, useState } from "react";
import { Link } from "@mui/material";
import { styled } from "@mui/system";
import parse, { domToReact, Element, HTMLReactParserOptions } from "html-react-parser";

import ContentBreadcrumbs from "./ContentBreadcrumb.web";
import OverviewSection from "./OverviewSection.web";
import PlainTextItem from "./PlanTextItem.web";
import { CustomSectionContainer, SectionListLayout } from "./CustomContainer.web";
import { ISectionData } from "./SymptomARD.web";

import { IContentData, ITermDetail } from "../../blocks/contentmanagement/src/ContentManagementController";

const CustomLink = styled(Link)({
  color: '#343C32',
  textDecorationColor: '#343C32'
})

const EatingDrinking = ({ data, onNavigate }: { data: IContentData, onNavigate: (to: string) => void }) => {
  const { attributes } = data;
  const { title, terms } = attributes;

  const parsingOptions: HTMLReactParserOptions = {
    replace(domNode) {
      if (domNode instanceof Element && domNode.name === 'li') {
        return (
          <PlainTextItem>{domToReact(domNode.children, parsingOptions)}</PlainTextItem>
        )
      }
      if (domNode instanceof Element && domNode.name === 'ul') {
        return (
          <>{domToReact(domNode.children, parsingOptions)}</>
        )
      }
      if (domNode instanceof Element && domNode.name === 'a') {
        return (
          <CustomLink href={domNode.attribs['href']} target="_blank" underline="none">
            {domToReact(domNode.children, parsingOptions)}
          </CustomLink>
        )
      }
    },
  }

  const [sectionList, setSectionList] = useState<ISectionData[]>([]);

  useEffect(() => {
    if (terms) {
      terms.sort((a, b) => a.id - b.id); 
      const groupedData: { [key: string]: ITermDetail[] } = {};
      terms.forEach(term => {
        const key = term.term;
        if (!groupedData[key]) {
          groupedData[key] = [];
        }
        groupedData[key].push(term);
      });
      const sections = Object.entries(groupedData).map(group => {
        if (group[1].length > 1) {
          return {
            name: group[0],
            definition: '',
            images: [],
            children: group[1].map(each => {
              return { definition: each.definition, images: each.images }
            })
          }
        }
        return {
          id: group[1][0].id,
          name: group[0],
          definition: group[1][0].definition,
          images: group[1][0].images,
        }
      })
      setSectionList(sections)
    }
  }, [terms]);
  
  return (
    <>
      <ContentBreadcrumbs stacks='Dashboard/Dementia/What to Expect/Eating and Drinking' onNavigate={onNavigate} />
      <SectionListLayout>
        <OverviewSection 
          images={title ? title?.images.map(image => image.url) : []} 
          name={title && parse(title?.title)} 
          shortDesc={title && title?.desc && title?.desc !== '' && parse(title?.desc)}
          containerStyle={{ p: { xs: '20px 16px', md: '40px 189px' } }}
        />
        {sectionList                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               
          .map((section, index) => (
            <CustomSectionContainer
              key={index}
              withHeading
              heading={parse(section.name)}
              containerStyle={{ px: { xs: 2, md: '130px' }, py: 2.5 }}
              contentStyle={{ width: 'fit-content', mt: 0, p: 2.5, gap: 1.5  }}
            >
              {parse(section.definition, parsingOptions)}
            </CustomSectionContainer>
          ))}
      </SectionListLayout>
    </>
  );
}

export default EatingDrinking;
